import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import AppLoader from "../../components/Helpers/AppLoader";

// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
import { Tab } from "@headlessui/react";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import SubmitButton from "../../components/Forms/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faCirclePlus, faPenCircle, faTrashCircle } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import SettingsDatePicker from "../../components/Forms/SettingsDatePicker";
import SettingsSwitch from "../../components/Forms/SettingsSwitch";
import PhoneInput from "react-phone-number-input";

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_apiKey,
//     authDomain: process.env.REACT_APP_authDomain,
//     projectId: process.env.REACT_APP_projectId,
//     storageBucket: process.env.REACT_APP_storageBucket,
//     messagingSenderId: process.env.REACT_APP_messagingSenderId,
//     appId: process.env.REACT_APP_appId,
// };

const navigation = [
    { name: "Applicant Information" },
    { name: "Joint Applicant Information" },
    { name: "Booking Information" },
    { name: "Next of Kin Information" },
    { name: "Affordability Calculator" },
    { name: "Audit Log" },
    { name: "Notes" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function EditUser() {
    const dispatch = useDispatch();
    const { user_id } = useParams();
    const { booking_id } = useParams();
    const { current_staff } = useSelector((state) => state.auth);

    // const app = initializeApp(firebaseConfig);
    // const db = getFirestore(app);

    // const [user, setUser] = useState([]);
    const [current_booking, setCurrentBooking] = useState([]);
    const [url, setUrl] = useState("");
    const [log, setLog] = useState({});
    const [notes, setNotes] = useState({});
    const { properties } = useSelector((state) => state.properties);
    const { settings } = useSelector((state) => state.settings);

    const [units, setUnits] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [profileInitialValues, setProfileInitialValues] = useState({});
    const [jointInitialValues, setJointInitialValues] = useState({});
    const [bookingInitialValues, setBookingInitialValues] = useState({});
    const [nextOfKinInitialValues, setNextOfKinInitialValues] = useState({});
    const [affordabilityCalculatorInitialValues, setAffordabilityCalculatorInitialValues] = useState({});

    const [loading, setLoading] = useState(true);
    const [auditloading, setAuditLoading] = useState(false);
    const [notesLoading, setNotesLoading] = useState(false);

    const [bankList, setBankList] = useState([]);

    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);

    useEffect(async () => {
        getUser();

        if (current_booking) {
            fetchNotes();
        }

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}paystack_banks`,
        })
            .then((response) => {
                let banks = response.data.data;
                let bankList = [];

                Object.keys(banks).forEach((key) => {
                    bankList.push({ key: banks[key].name, value: banks[key].name, label: banks[key].name, code: banks[key].code });
                });

                setBankList(bankList);
            })
            .catch((error) => {
                alert("Bank failed");
            });

        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }
    }, []);

    async function getUser() {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}booking_user_id/${user_id}/${booking_id}`,
        }).then((response) => {
            const data = response.data;

            fetchAudit();
            // setUser(response.data);

            setCurrentBooking(data);

            if (data.property_id) {
                getUnits(data.property_id);
                getUnitTypes(data.property_id);
            }

            determineInitialValues(data);
            setLoading(false);
        });
    }

    function formatToFloat(value) {
        if (value) {
            return parseFloat(value.replace(/,/g, ""));
        } else {
            return value;
        }
    }

    function availableIncome(income, outcome) {
        var money_in = formatToFloat(income);
        var money_out = formatToFloat(outcome);

        return money_in - money_out;
    }

    async function getUnits(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}units/${id}`,
        }).then((response) => {
            setUnits(response.data);
        });
    }

    async function getUnitTypes(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
        }).then((response) => {
            setUnitTypes(response.data);
        });
    }

    async function determineInitialValues(data) {
        setUrl(data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url);

        // console.log(data);

        setProfileInitialValues({
            url: data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url,
            name: data.user.name,
            surname: data.user.surname,
            email: data.user.email,
            // cell: data.user.cell,
            cell: "+" + data.user.cell,
            title: data.user.profile ? data.user.profile.title : "",
            nationality: data.user.profile ? data.user.profile.nationality : "",
            id_number: data.user.profile ? data.user.profile.id_number : "",
            car: data.user.profile ? data.user.profile.car : "",
            occupation: data.user.profile ? data.user.profile.occupation : "",
            home_number: data.user.profile ? "+" + data.user.profile.home_number : "",
            company_application: data.company_application ? data.company_application : 0,
            employer: data.user.profile ? data.user.profile.employer : "",
            employer_address: data.user.profile ? data.user.profile.employer_address : "",
            employer_suburb: data.user.profile ? data.user.profile.employer_suburb : "",
            employer_city: data.user.profile ? data.user.profile.employer_city : "",
            employer_province: data.user.profile ? data.user.profile.employer_province : "",
            employer_postal: data.user.profile ? data.user.profile.employer_postal : "",
            employer_registration: data.user.profile ? data.user.profile.employer_registration : "",
            employer_it: data.user.profile ? data.user.profile.employer_it : "",
            employer_contact: data.user.profile ? "+" + data.user.profile.employer_contact : "",
            date_of_birth: data.user.profile ? data.user.profile.date_of_birth : "",
            banking_bank: data.user.profile ? data.user.profile.banking_bank : "",
            banking_account_type: data.user.profile ? data.user.profile.banking_account_type : "",
            banking_branch: data.user.profile ? data.user.profile.banking_branch : "",
            banking_branch_code: data.user.profile ? data.user.profile.banking_branch_code : "",
            banking_account_number: data.user.profile ? data.user.profile.banking_account_number : "",
            banking_account_holder: data.user.profile ? data.user.profile.banking_account_holder : "",
            criminal_record: data.user.profile ? data.user.profile.criminal_record : "",
            other: data.user.profile ? data.user.profile.other : "",
            current_address: data.user.premises ? data.user.premises.current_address : "",
            current_suburb: data.user.premises ? data.user.premises.current_suburb : "",
            current_city: data.user.premises ? data.user.premises.current_city : "",
            current_postal: data.user.premises ? data.user.premises.current_postal : "",
            current_province: data.user.premises ? data.user.premises.current_province : "",
            landlord_name: data.user.premises ? data.user.premises.landlord_name : "",
            landlord_contact: data.user.premises && data.user.premises.landlord_contact ? "+" + data.user.premises.landlord_contact : "",
        });

        setJointInitialValues({
            url: data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url,
            joint_application: data.joint_application,
            joint_application_title: data.user.joint_application ? data.user.joint_application.title : "",
            joint_application_name: data.user.joint_application ? data.user.joint_application.name : "",
            joint_application_surname: data.user.joint_application ? data.user.joint_application.surname : "",
            joint_application_email: data.user.joint_application ? data.user.joint_application.email : "",
            joint_application_id_number: data.user.joint_application ? data.user.joint_application.id_number : "",
            joint_application_nationality: data.user.joint_application ? data.user.joint_application.nationality : "",
            joint_application_cell: data.user.joint_application && data.user.joint_application.cell ? "+" + data.user.joint_application.cell : "",
            joint_application_date_of_birth: data.user.joint_application ? data.user.joint_application.date_of_birth : "",
            joint_application_employer: data.user.joint_application ? data.user.joint_application.employer : "",

            joint_application_gross_salary: data.user.joint_application ? data.user.joint_application.gross_salary : "",
            joint_application_other_income: data.user.joint_application ? data.user.joint_application.other_income : "",
            joint_application_net_income: data.user.joint_application ? data.user.joint_application.net_income : "",
            joint_application_rent: data.user.joint_application ? data.user.joint_application.rent : "",
            joint_application_car_repayments: data.user.joint_application ? data.user.joint_application.car_repayments : "",
            joint_application_loan_repayments: data.user.joint_application ? data.user.joint_application.loan_repayments : "",
            joint_application_card_repayments: data.user.joint_application ? data.user.joint_application.card_repayments : "",
            joint_application_groceries_costs: data.user.joint_application ? data.user.joint_application.groceries_costs : "",
            joint_application_fuel_costs: data.user.joint_application ? data.user.joint_application.fuel_costs : "",
            joint_application_school_fees: data.user.joint_application ? data.user.joint_application.school_fees : "",
            joint_application_medical_aid: data.user.joint_application ? data.user.joint_application.medical_aid : "",
            joint_application_total_monthly: data.user.joint_application ? data.user.joint_application.total_monthly : "",
            joint_application_available_income: data.user.joint_application ? data.user.joint_application.available_income : "",
            joint_application_other: data.user.joint_application ? data.user.joint_application.other : "",

            joint_application_banking_bank: data.user.joint_application ? data.user.joint_application.banking_bank : "",
            joint_application_banking_account_type: data.user.joint_application ? data.user.joint_application.banking_account_type : "",
            joint_application_banking_branch: data.user.joint_application ? data.user.joint_application.banking_branch : "",
            joint_application_banking_branch_code: data.user.joint_application ? data.user.joint_application.banking_branch_code : "",
            joint_application_banking_account_number: data.user.joint_application ? data.user.joint_application.banking_account_number : "",
            joint_application_banking_account_holder: data.user.joint_application ? data.user.joint_application.banking_account_holder : "",

            joint_application_current_address: data.user.joint_application ? data.user.joint_application.current_address : "",
            joint_application_current_suburb: data.user.joint_application ? data.user.joint_application.current_suburb : "",
            joint_application_current_city: data.user.joint_application ? data.user.joint_application.current_city : "",
            joint_application_current_postal: data.user.joint_application ? data.user.joint_application.current_postal : "",
            joint_application_current_province: data.user.joint_application ? data.user.joint_application.current_province : "",
        });

        setBookingInitialValues({
            url: data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url,
            property_id: data.property_id,
            unit_id: data.unit_id,
            unit_type: data.unit_type.id,

            occupy_unit: data.user.premises ? data.user.premises.occupy_unit : "",
            parking: data.user.premises ? data.user.premises.parking : "",
            // viewed_unit: data.user.premises ? data.user.premises.viewed_unit : "",
            occupants_over_10: data.user.premises ? data.user.premises.occupants_over_10 : "",
            occupants: data.user.premises ? data.user.premises.occupants : "",
            referral: data.user.premises ? data.user.premises.referral : "",
            // assisted_by: data.user.premises ? data.user.premises.assisted_by : "",

            invoice_monthly_total: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].invoice_monthly_total : "",
            invoice_monthly_hide: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].invoice_monthly_hide : "",
            parking_fee: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].parking_fee : "",
            monthly_rental: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].monthly_rental : "",
            monthly_rental_wording: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].monthly_rental_wording : "",
            admin_fee: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].admin_fee : "",
            deposit_price: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].deposit_price : "",
            booking_fee_price: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].booking_fee_price : "",
            key_deposit: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].key_deposit : "",
            carport: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].carport : "",
            special_wording: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].special_wording : "",
            effluent: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].effluent : "",
            special_deduction: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].special_deduction : "",
            refuse: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].refuse : "",
            lease_start: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].lease_start : "",
            lease_end: data.rates[data.rates.length - 1] ? data.rates[data.rates.length - 1].lease_end : "",
        });

        setNextOfKinInitialValues({
            url: data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url,
            kin_title: data.user.next_of_kin ? data.user.next_of_kin.title : "",
            kin_name: data.user.next_of_kin ? data.user.next_of_kin.name : "",
            kin_surname: data.user.next_of_kin ? data.user.next_of_kin.surname : "",
            kin_relationship: data.user.next_of_kin ? data.user.next_of_kin.relationship : "",
            kin_contact: data.user.next_of_kin && data.user.next_of_kin.contact ? "+" + data.user.next_of_kin.contact : "",
            kin_email: data.user.next_of_kin ? data.user.next_of_kin.email : "",
            kin_address: data.user.next_of_kin ? data.user.next_of_kin.address : "",
        });

        setAffordabilityCalculatorInitialValues({
            url: data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url,
            gross_salary: data.user.affordability ? data.user.affordability.gross_salary : "",
            other_income: data.user.affordability ? data.user.affordability.other_income : "",
            net_income: data.user.affordability ? data.user.affordability.net_income : "",
            rent: data.user.affordability ? data.user.affordability.rent : "",
            car_repayments: data.user.affordability ? data.user.affordability.car_repayments : "",
            loan_repayments: data.user.affordability ? data.user.affordability.loan_repayments : "",
            card_repayments: data.user.affordability ? data.user.affordability.card_repayments : "",
            groceries_costs: data.user.affordability ? data.user.affordability.groceries_costs : "",
            fuel_costs: data.user.affordability ? data.user.affordability.fuel_costs : "",
            school_fees: data.user.affordability ? data.user.affordability.school_fees : "",
            medical_aid: data.user.affordability ? data.user.affordability.medical_aid : "",
            total_monthly: data.user.affordability ? data.user.affordability.total_monthly : "",
            available_income: data.user.affordability ? data.user.affordability.available_income : "",
            other: data.user.affordability ? data.user.affordability.other : "",
        });
    }

    if (loading === true) {
        return <AppLoader />;
    }

    async function fetchAudit() {
        setAuditLoading(true);
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}log/${user_id}`,
        })
            .then((response) => {
                setLog(response.data);
            })
            .finally(() => {
                setAuditLoading(false);
            });
    }

    async function fetchNotes() {
        setNotesLoading(true);
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}legacy_notes/${current_booking.id}`,
        })
            .then((response) => {
                setNotes(response.data);
                // console.log(response.data);
            })
            .finally(() => {
                setNotesLoading(false);
            });
    }

    function formatIcon(status) {
        let icon = faCirclePlus;

        switch (status) {
            case "deleted":
                icon = faTrashCircle;
                break;
            case "updated":
                icon = faPenCircle;
                break;
            default:
                break;
        }

        return icon;
    }

    function formatIconClass(status) {
        let icon_class = " text-green-600";

        switch (status) {
            case "deleted":
                icon_class = "text-red-600";
                break;
            case "updated":
                icon_class = "text-yellow-500";
                break;
            default:
                break;
        }

        return icon_class;
    }

    // make audit entry clear to read
    function formatLogBooleans(value) {
        switch (value) {
            case "0":
            case 0:
            case false:
                return "False";
            case "1":
            case 1:
            case true:
                return "True";
            case "":
            case null:
                return "N/A";
            default:
                return value;
        }
    }

    function formatLogModel(model) {
        let value = model.split("\\");
        return value[value.length - 1];
    }

    function formatLogTitle(log) {
        if (formatLogModel(log.auditable_type) === "File" && log.new_values["name"]) {
            return "(" + log.new_values["name"] + ")";
        }
    }

    // const profile_initial_values = {
    // };
    return (
        <>
            <div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen xl:flex w-full">
                <div className="divide-y divide-gray-200 max-h-76 overflow-y-scroll xl:max-h-full xl:grid xl:grid-cols-12 xl:divide-y-0 xl:divide-x">
                    <Tab.Group>
                        <Tab.List className="bg-light_background xl:col-span-2 rounded-3xl w-full xl:w-72 border-0">
                            <div className="h-14 flex justify-evenly rounded-t-3xl items-center bg-dark_background">
                                <Link
                                    className="text-md leading-6 text-white"
                                    to={{
                                        pathname: "/booking/" + current_booking.location.toLowerCase().replace(/\s/g, ""),
                                        search: "?user_id=" + current_booking.user?.id + "&booking_id=" + current_booking.id,
                                        state: { from: "editUser", userUid: current_booking.user.id },
                                    }}
                                >
                                    <FontAwesomeIcon className="mr-4" icon={regular("arrow-left-long")} />
                                </Link>
                                <h1 className="text-xl leading-6 font-light text-white capitalize">Editing {current_booking.user.name + " " + current_booking.user.surname}</h1>
                                <FontAwesomeIcon className="mr-4 text-white" icon={regular("trash")} />
                            </div>
                            {navigation.map((item) => (
                                <Tab
                                    key={item.name}
                                    className={({ selected }) =>
                                        classNames(
                                            "group w-full flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none transition ease-linear delay-10",
                                            selected ? "border-l-primary bg-gray-100" : "bg-light_background border-l-light_background"
                                        )
                                    }
                                >
                                    <span className="truncate">{item.name}</span>
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels className="my-6 xl:my-0 xl:ml-28 2xl:ml-4 rounded-3xl bg-gray-100 xl:col-span-9">
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background ">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Applicant Information</h1>
                                </div>
                                <Formik
                                    initialValues={profileInitialValues}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);

                                        //format cell
                                        values.cell = values.cell && values.cell.replace("+", "");
                                        values.home_number = values.home_number && values.home_number.replace("+", "");
                                        values.employer_contact = values.employer_contact && values.employer_contact.replace("+", "");
                                        values.landlord_contact = values.landlord_contact && values.landlord_contact.replace("+", "");

                                        await Object.keys(bankList).forEach((key) => {
                                            if (values.banking_bank == bankList[key].label) {
                                                values.banking_bank_code = bankList[key].code;
                                            }
                                        });

                                        let submitData = {
                                            user: current_staff,
                                            url: url,
                                            name: values.name,
                                            surname: values.surname,
                                            email: values.email,
                                            cell: values.cell,
                                            title: values.title,
                                            nationality: values.nationality,
                                            id_number: values.id_number,
                                            date_of_birth: values.date_of_birth,
                                            car: values.car,
                                            occupation: values.occupation,

                                            home_number: values.home_number,
                                            company_application: values.company_application,
                                            employer: values.employer,
                                            employer_address: values.employer_address,
                                            employer_suburb: values.employer_suburb,
                                            employer_city: values.employer_city,
                                            employer_province: values.employer_province,
                                            employer_postal: values.employer_postal,
                                            employer_registration: values.employer_registration,
                                            employer_it: values.employer_it,
                                            employer_contact: values.employer_contact,
                                            banking_bank: values.banking_bank,
                                            banking_bank_code: values.banking_bank_code,
                                            banking_account_type: values.banking_account_type,
                                            banking_branch: values.banking_branch,
                                            banking_branch_code: values.banking_branch_code,
                                            banking_account_number: values.banking_account_number,
                                            banking_account_holder: values.banking_account_holder,
                                            criminal_record: values.criminal_record,
                                            other: values.other,

                                            current_address: values.current_address,
                                            current_suburb: values.current_suburb,
                                            current_city: values.current_city,
                                            current_postal: values.current_postal,
                                            current_province: values.current_province,
                                            landlord_name: values.landlord_name,
                                            landlord_contact: values.landlord_contact,
                                        };

                                        await axios({
                                            method: "put",
                                            url: `${process.env.REACT_APP_API_URL}user`,
                                            data: submitData,
                                        })
                                            .then((resp) => {
                                                if (resp.data && resp.data.length > 0 && resp.data[0] == "error") {
                                                    toast(<NotificationToast title="Something went wrong" message={resp.data[1]} type="error" />);
                                                } else {
                                                    toast(
                                                        <NotificationToast
                                                            title={current_booking.user.name + " " + current_booking.user.surname + " updated"}
                                                            message={"Your changes have been saved."}
                                                        />
                                                    );
                                                }
                                                getUser();

                                                //Add the + back for form prettiness
                                                values.cell = "+" + values.cell;
                                                values.home_number = "+" + values.home_number;
                                                values.employer_contact = "+" + values.employer_contact;
                                                values.landlord_contact = "+" + values.landlord_contact;
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                console.log(error.response);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                                resetForm();
                                            });
                                    }}
                                >
                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">This information is related to the applicant and contains their details such as name and email address.</p>

                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <SettingsSelect
                                                        label="Title"
                                                        name="title"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.title}
                                                        touched={touched.title}
                                                        error={errors.title}
                                                        options="titles"
                                                    />
                                                    <Field name="name" type="text" as={SettingsTextInput} label="Name" />
                                                    <Field name="surname" type="text" as={SettingsTextInput} label="Surname" />
                                                    <Field name="email" type="email" as={SettingsTextInput} label="Email" />
                                                    {/* <Field name="cell" type="text" as={SettingsTextInput} prepend="+" label="Cell" /> */}
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Cell</span>
                                                            {errors.cell && <p className="text-xs font-light text-red-400">required</p>}
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("cell", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="cell"
                                                                value={values.cell}
                                                            />
                                                            <p className="text-red-400 text-sm font-light">{errors.cell}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="home_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Home Number</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("home_number", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="home_number"
                                                                value={values.home_number}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <Field name="home_number" type="text" as={SettingsTextInput} label="Home Number" prepend="+" /> */}
                                                    <SettingsSelect
                                                        label="Nationality"
                                                        name="nationality"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.nationality}
                                                        touched={touched.nationality}
                                                        error={errors.nationality}
                                                        options="nationality"
                                                    />
                                                    <Field name="id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" nationality={values.nationality} />
                                                    <Field
                                                        name="date_of_birth"
                                                        type="text"
                                                        as={SettingsDatePicker}
                                                        label="Date of Birth"
                                                        id_number={values.id_number}
                                                        nationality={values.nationality}
                                                    />
                                                    <Field name="car" type="text" as={SettingsTextInput} label="Car Model and Registration" />
                                                </div>
                                                <div className="pt-12 grid grid-cols-6 gap-6">
                                                    <Field name="occupation" type="text" as={SettingsTextInput} label="Occupation" />
                                                    <Field name="employer" type="text" as={SettingsTextInput} label="Employer" />
                                                    <Field name="employer_address" type="text" as={SettingsTextInput} label="Employer Address" />
                                                    {values.company_application && (
                                                        <>
                                                            <Field name="employer_suburb" type="text" as={SettingsTextInput} label="Employer Suburb" required />
                                                            <Field name="employer_city" type="text" as={SettingsTextInput} label="Employer City" required />
                                                            <Field name="employer_postal" type="text" as={SettingsTextInput} label="Employer Postal Code" required />
                                                            <SettingsSelect
                                                                label="Employer Province"
                                                                name="employer_province"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.employer_province}
                                                                touched={touched.employer_province}
                                                                error={errors.employer_province}
                                                                options="provinces"
                                                                required
                                                            />

                                                            <Field
                                                                name="employer_registration"
                                                                as={SettingsTextInput}
                                                                label="Employer Registration Number"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                business_registration={true}
                                                                required
                                                            />
                                                            <Field name="employer_it" type="text" as={SettingsTextInput} label="Employer IT Number" required />
                                                        </>
                                                    )}
                                                    {/* <Field name="employer_contact" type="text" as={SettingsTextInput} label="Employer Contact Number" prepend="+" /> */}
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="employer_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Employer Contact Number</span>
                                                            {errors.employer_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("employer_contact", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="employer_contact"
                                                                value={values.employer_contact}
                                                            />
                                                            <p className="text-red-400 text-sm font-light">{errors.employer_contact}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-12 grid grid-cols-6 gap-6">
                                                    <SettingsSelect
                                                        label="Bank"
                                                        name="banking_bank"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.banking_bank}
                                                        touched={touched.banking_bank}
                                                        error={errors.banking_bank}
                                                        options={bankList}
                                                    />
                                                    {/* <Field name="banking_bank" type="text" as={SettingsTextInput} label="Bank" /> */}
                                                    <Field name="banking_account_type" type="text" as={SettingsTextInput} label="Account Type" />
                                                    <Field name="banking_branch" type="text" as={SettingsTextInput} label="Branch" />
                                                    <Field name="banking_branch_code" type="text" as={SettingsTextInput} label="Branch Code" />
                                                    <Field name="banking_account_number" type="text" as={SettingsTextInput} label="Account Number" />
                                                    <Field name="banking_account_holder" type="text" as={SettingsTextInput} label="Account Holder" />
                                                </div>
                                                <div className="pt-12 grid grid-cols-6 gap-6">
                                                    <SettingsSelect
                                                        label="Criminal Record"
                                                        name="criminal_record"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.criminal_record}
                                                        touched={touched.criminal_record}
                                                        error={errors.criminal_record}
                                                        options="yesNo"
                                                    />
                                                    <Field name="other" field_type="textarea" as={SettingsTextInput} label="Other" />
                                                </div>
                                                {!values.company_application && (
                                                    <div className="grid grid-cols-6 gap-6 mt-6">
                                                        <Field name="current_address" type="text" as={SettingsTextInput} label="Current Address" />
                                                        <Field name="current_suburb" type="text" as={SettingsTextInput} label="Current Suburb" />
                                                        <Field name="current_city" type="text" as={SettingsTextInput} label="Current City" />
                                                        <Field name="current_postal" type="text" as={SettingsTextInput} label="Current Postal Code" />
                                                        <SettingsSelect
                                                            label="Current Province"
                                                            name="current_province"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.current_province}
                                                            touched={touched.current_province}
                                                            error={errors.current_province}
                                                            options="provinces"
                                                        />
                                                        <Field name="landlord_name" type="text" as={SettingsTextInput} label="Landlord Name" />
                                                        {/* <Field name="landlord_contact" type="text" as={SettingsTextInput} label="Landlord Contact" prepend="+" /> */}
                                                        <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                            <label htmlFor="landlord_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                <span>Landlord Contact</span>
                                                                {errors.landlord_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                            </label>
                                                            <div className="mt-1">
                                                                <PhoneInput
                                                                    defaultCountry="ZA"
                                                                    countryCallingCodeEditable={false}
                                                                    onChange={(val) => {
                                                                        setFieldValue("landlord_contact", val);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    name="landlord_contact"
                                                                    value={values.landlord_contact}
                                                                />
                                                                <p className="text-red-400 text-sm font-light">{errors.landlord_contact}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="mt-8">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={regular("save")}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Tab.Panel>
                            {/* Joint Application */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Joint Application Information</h1>
                                </div>
                                <Formik
                                    initialValues={jointInitialValues}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);

                                        //format cell
                                        values.joint_application_cell = values.joint_application_cell && values.joint_application_cell.replace("+", "");

                                        await Object.keys(bankList).forEach((key) => {
                                            if (values.joint_application_banking_bank == bankList[key].label) {
                                                values.banking_bank_code_joint = bankList[key].code;
                                            }
                                        });

                                        let submitData = {
                                            user: current_staff,
                                            url: url,
                                            joint_application: values.joint_application,
                                            joint_application_title: values.joint_application_title,
                                            joint_application_name: values.joint_application_name,
                                            joint_application_surname: values.joint_application_surname,
                                            joint_application_email: values.joint_application_email,
                                            joint_application_cell: values.joint_application_cell,
                                            joint_application_nationality: values.joint_application_nationality,
                                            joint_application_id_number: values.joint_application_id_number,
                                            joint_application_date_of_birth: values.joint_application_date_of_birth,
                                            joint_application_employer: values.joint_application_employer,

                                            joint_application_gross_salary: formatToFloat(values.joint_application_gross_salary),
                                            joint_application_other_income: formatToFloat(values.joint_application_other_income),
                                            joint_application_net_income: formatToFloat(values.joint_application_net_income),
                                            joint_application_rent: formatToFloat(values.joint_application_rent),
                                            joint_application_car_repayments: formatToFloat(values.joint_application_car_repayments),
                                            joint_application_loan_repayments: formatToFloat(values.joint_application_loan_repayments),
                                            joint_application_card_repayments: formatToFloat(values.joint_application_card_repayments),
                                            joint_application_groceries_costs: formatToFloat(values.joint_application_groceries_costs),
                                            joint_application_fuel_costs: formatToFloat(values.joint_application_fuel_costs),
                                            joint_application_school_fees: formatToFloat(values.joint_application_school_fees),
                                            joint_application_medical_aid: formatToFloat(values.joint_application_medical_aid),
                                            joint_application_total_monthly: formatToFloat(values.joint_application_total_monthly),
                                            joint_application_available_income: availableIncome(values.joint_application_net_income, values.joint_application_total_monthly),
                                            joint_application_other: values.joint_application_other,

                                            joint_application_banking_bank: values.joint_application_banking_bank,
                                            banking_bank_code_joint: values.banking_bank_code_joint,
                                            joint_application_banking_account_type: values.joint_application_banking_account_type,
                                            joint_application_banking_branch: values.joint_application_banking_branch,
                                            joint_application_banking_branch_code: values.joint_application_banking_branch_code,
                                            joint_application_banking_account_number: values.joint_application_banking_account_number,
                                            joint_application_banking_account_holder: values.joint_application_banking_account_holder,

                                            joint_application_current_address: values.joint_application_current_address,
                                            joint_application_current_suburb: values.joint_application_current_suburb,
                                            joint_application_current_city: values.joint_application_current_city,
                                            joint_application_current_postal: values.joint_application_current_postal,
                                            joint_application_current_province: values.joint_application_current_province,
                                        };

                                        await axios({
                                            method: "put",
                                            url: `${process.env.REACT_APP_API_URL}user`,
                                            data: submitData,
                                        })
                                            .then(() => {
                                                toast(
                                                    <NotificationToast title={current_booking.user.name + " " + current_booking.user.surname + " updated"} message={"Your changes have been saved."} />
                                                );
                                                getUser();

                                                //Add the + back for form prettiness
                                                values.joint_application_cell = "+" + values.joint_application_cell;
                                            })
                                            .catch((error) => {
                                                console.log(error.response);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                                resetForm();
                                            });
                                    }}
                                >
                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">
                                                    This information is related to the joint application, if applicable, and contains the joint applicants details such as name and email address.
                                                </p>

                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <SettingsSelect
                                                        label="Joint Application"
                                                        name="joint_application"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.joint_application}
                                                        touched={touched.joint_application}
                                                        error={errors.joint_application}
                                                        options="yesNo"
                                                    />
                                                </div>
                                                {values.joint_application === 1 && (
                                                    <div className="grid grid-cols-6 gap-6 mt-12">
                                                        <SettingsSelect
                                                            label="Title"
                                                            name="joint_application_title"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.joint_application_title}
                                                            touched={touched.joint_application_title}
                                                            error={errors.joint_application_title}
                                                            options="titles"
                                                        />
                                                        <Field name="joint_application_name" type="text" as={SettingsTextInput} label="Name" />
                                                        <Field name="joint_application_surname" type="text" as={SettingsTextInput} label="Surname" />
                                                        <Field name="joint_application_email" type="email" as={SettingsTextInput} label="Email" />
                                                        {/* <Field name="joint_application_cell" type="text" as={SettingsTextInput} label="Cell Number" prepend="+" /> */}
                                                        <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                            <label htmlFor="joint_application_cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                <span>Cell Number</span>
                                                                {errors.joint_application_cell && <p className="text-xs font-light text-red-400">required</p>}
                                                            </label>
                                                            <div className="mt-1">
                                                                <PhoneInput
                                                                    defaultCountry="ZA"
                                                                    countryCallingCodeEditable={false}
                                                                    onChange={(val) => {
                                                                        setFieldValue("joint_application_cell", val);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    name="joint_application_cell"
                                                                    value={values.joint_application_cell}
                                                                />
                                                                <p className="text-red-400 text-sm font-light">{errors.joint_application_cell}</p>
                                                            </div>
                                                        </div>
                                                        <SettingsSelect
                                                            label="Nationality"
                                                            name="joint_application_nationality"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.joint_application_nationality}
                                                            touched={touched.joint_application_nationality}
                                                            error={errors.joint_application_nationality}
                                                            options="nationality"
                                                        />
                                                        <Field
                                                            name="joint_application_id_number"
                                                            type="text"
                                                            as={SettingsTextInput}
                                                            label="ID or Passport Number"
                                                            nationality={values.joint_application_nationality}
                                                        />
                                                        <Field
                                                            name="joint_application_date_of_birth"
                                                            type="text"
                                                            as={SettingsDatePicker}
                                                            label="Date of Birth"
                                                            id_number={values.joint_application_id_number}
                                                            nationality={values.joint_application_nationality}
                                                        />
                                                        <Field name="joint_application_employer" type="text" as={SettingsTextInput} label="Employer" />

                                                        <Field name="joint_application_gross_salary" as={SettingsTextInput} label="Gross Salary" prepend="R" currency="true" />
                                                        <Field name="joint_application_other_income" as={SettingsTextInput} label="Other Income" prepend="R" currency="true" />
                                                        <Field name="joint_application_net_income" as={SettingsTextInput} label="Net Income (A)" prepend="R" currency="true" />
                                                        <Field name="joint_application_rent" as={SettingsTextInput} label="Rent" prepend="R" currency="true" />
                                                        <Field name="joint_application_car_repayments" as={SettingsTextInput} label="Car Repayments" prepend="R" currency="true" />
                                                        <Field name="joint_application_loan_repayments" as={SettingsTextInput} label="Loan Repayments" prepend="R" currency="true" />
                                                        <Field name="joint_application_card_repayments" as={SettingsTextInput} label="Card Repayments" prepend="R" currency="true" />
                                                        <Field name="joint_application_groceries_costs" as={SettingsTextInput} label="Groceries Costs" prepend="R" currency="true" />
                                                        <Field name="joint_application_fuel_costs" as={SettingsTextInput} label="Fuel Costs" prepend="R" currency="true" />
                                                        <Field name="joint_application_school_fees" as={SettingsTextInput} label="School Fees" prepend="R" currency="true" />
                                                        <Field name="joint_application_medical_aid" as={SettingsTextInput} label="Medical Aid" prepend="R" currency="true" />
                                                        <Field name="joint_application_total_monthly" as={SettingsTextInput} label="Total Monthly Expenses (B)" prepend="R" currency="true" />
                                                        <Field
                                                            name="joint_application_available_income"
                                                            as={SettingsTextInput}
                                                            label="Available Income (A-B)"
                                                            disabled
                                                            value={availableIncome(values.net_income, values.total_monthly)}
                                                            prepend="R"
                                                            currency="true"
                                                        />
                                                        <Field name="joint_application_other" field_type="textarea" as={SettingsTextInput} label="Other" />
                                                        <Field name="joint_application_current_address" type="text" as={SettingsTextInput} label="Current Address" />
                                                        <Field name="joint_application_current_suburb" type="text" as={SettingsTextInput} label="Current Suburb" />
                                                        <Field name="joint_application_current_city" type="text" as={SettingsTextInput} label="Current City" />
                                                        <Field name="joint_application_current_postal" type="text" as={SettingsTextInput} label="Current Postal Code" />
                                                        <SettingsSelect
                                                            label="Current Province"
                                                            name="joint_application_current_province"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.joint_application_current_province}
                                                            touched={touched.joint_application_current_province}
                                                            error={errors.joint_application_current_province}
                                                            options="provinces"
                                                        />
                                                        <SettingsSelect
                                                            label="Bank"
                                                            name="joint_application_banking_bank"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.joint_application_banking_bank}
                                                            touched={touched.joint_application_banking_bank}
                                                            error={errors.joint_application_banking_bank}
                                                            options={bankList}
                                                        />
                                                        {/* <Field name="joint_application_banking_bank" type="text" as={SettingsTextInput} label="Bank" /> */}
                                                        <Field name="joint_application_banking_account_type" type="text" as={SettingsTextInput} label="Account Type" />
                                                        <Field name="joint_application_banking_branch" type="text" as={SettingsTextInput} label="Branch" />
                                                        <Field name="joint_application_banking_branch_code" type="text" as={SettingsTextInput} label="Branch Code" />
                                                        <Field name="joint_application_banking_account_number" type="text" as={SettingsTextInput} label="Account Number" />
                                                        <Field name="joint_application_banking_account_holder" type="text" as={SettingsTextInput} label="Account Holder" />
                                                    </div>
                                                )}

                                                <div className="mt-8">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={regular("save")}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Tab.Panel>
                            {/* Premises */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Booking Information</h1>
                                </div>
                                <Formik
                                    initialValues={bookingInitialValues}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);
                                        let submitData = {
                                            user: current_staff,
                                            url: url,
                                            property_id: values.property_id,
                                            unit_id: values.unit_id,
                                            unit_type: values.unit_type,

                                            occupy_unit: values.occupy_unit,
                                            occupants: values.occupants,
                                            occupants_over_10: values.occupants_over_10,

                                            // parking: values.parking,
                                            parking: values.carport,
                                            // viewed_unit: values.viewed_unit,
                                            referral: values.referral,
                                            // assisted_by: values.assisted_by,

                                            parking_fee: formatToFloat(values.parking_fee),
                                            monthly_rental: formatToFloat(values.monthly_rental),
                                            deposit_price: formatToFloat(values.deposit_price),
                                            booking_fee_price: formatToFloat(values.booking_fee_price),
                                            admin_fee: formatToFloat(values.admin_fee),
                                            key_deposit: formatToFloat(values.key_deposit),
                                            effluent: formatToFloat(values.effluent),
                                            special_deduction: formatToFloat(values.special_deduction),
                                            refuse: formatToFloat(values.refuse),

                                            carport: values.carport,
                                            invoice_monthly_hide: values.invoice_monthly_hide,
                                            invoice_monthly_total: values.invoice_monthly_total,
                                            monthly_rental_wording: values.monthly_rental_wording,
                                            special_wording: values.special_wording,

                                            lease_start: values.lease_start,
                                            lease_end: values.lease_end,
                                        };

                                        await axios({
                                            method: "put",
                                            url: `${process.env.REACT_APP_API_URL}user`,
                                            data: submitData,
                                        })
                                            .then(() => {
                                                toast(
                                                    <NotificationToast title={current_booking.user.name + " " + current_booking.user.surname + " updated"} message={"Your changes have been saved."} />
                                                );
                                                getUser();
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                console.log(error.response);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                                resetForm();
                                            });
                                    }}
                                >
                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">This information is related to the applicant and contains their details such as name and email address.</p>

                                                <div className="pt-4 text-gray-800">
                                                    <p className="font-medium">Booking Information</p>
                                                </div>
                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <SettingsSelect
                                                        label="Building"
                                                        name="property_id"
                                                        required
                                                        getUnits={getUnits}
                                                        getUnitTypes={getUnitTypes}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.property_id}
                                                        touched={touched.property_id}
                                                        error={errors.property_id}
                                                        options={properties}
                                                    />
                                                    <SettingsSelect
                                                        label="Unit Type"
                                                        name="unit_type"
                                                        required
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.unit_type}
                                                        touched={touched.unit_type}
                                                        error={errors.unit_type}
                                                        options={unitTypes.length > 0 ? unitTypes : "none"}
                                                    />
                                                    <SettingsSelect
                                                        label="Unit"
                                                        name="unit_id"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.unit_id}
                                                        touched={touched.unit_id}
                                                        error={errors.unit_id}
                                                        options={units.length > 0 ? units : "none"}
                                                    />
                                                    <SettingsSelect
                                                        label="Will this Applicant occupy the premises?                                                        "
                                                        name="occupy_unit"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.occupy_unit}
                                                        touched={touched.occupy_unit}
                                                        error={errors.occupy_unit}
                                                        options="yesNo"
                                                    />
                                                    <Field
                                                        name="carport"
                                                        as={SettingsTextInput}
                                                        label="Parking / Carport"
                                                        tooltip="Enter the Carport / Garage Number here, otherwise leave blank if not applicable"
                                                    />
                                                    {values.carport && <Field name="parking_fee" as={SettingsTextInput} label="Parking Fee" prepend="R" currency="true" />}
                                                    {/* <SettingsSelect
                                                        label="Viewed the Unit"
                                                        name="viewed_unit"
                                                        onChange={ setFieldValue }
                                                        onBlur={ setFieldTouched }
                                                        value={ values.viewed_unit }
                                                        touched={ touched.viewed_unit }
                                                        error={ errors.viewed_unit }
                                                        options="yesNo"
                                                    /> */}
                                                    <Field name="occupants" type="text" as={SettingsTextInput} label="Occupants" />
                                                    {values.occupants && (
                                                        <SettingsSelect
                                                            label="Any occupants under the age of 10?"
                                                            name="occupants_over_10"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.occupants_over_10}
                                                            touched={touched.occupants_over_10}
                                                            error={errors.occupants_over_10}
                                                            options="yesNo"
                                                        />
                                                    )}
                                                    {/* <SettingsSelect
                                                        label="Assisted By"
                                                        name="assisted_by"
                                                        onChange={ setFieldValue }
                                                        onBlur={ setFieldTouched }
                                                        value={ values.assisted_by }
                                                        touched={ touched.assisted_by }
                                                        error={ errors.assisted_by }
                                                        options="assisted_by"
                                                    /> */}
                                                    <SettingsSelect
                                                        label="Referral"
                                                        name="referral"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.referral}
                                                        touched={touched.referral}
                                                        error={errors.referral}
                                                        options="referral"
                                                    />
                                                </div>
                                                <p className="font-medium mt-8 text-gray-800">Pricing & Lease</p>
                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <Field name="monthly_rental" as={SettingsTextInput} label="Monthly Rental" prepend="R" currency="true" />
                                                    <Field name="deposit_price" as={SettingsTextInput} label="Deposit" prepend="R" currency="true" />
                                                    {/* <Field name="booking_fee_price" as={SettingsTextInput} label="Booking Fee" prepend="R" currency="true" /> */}
                                                    <Field name="admin_fee" as={SettingsTextInput} label="Admin Fee" prepend="R" currency="true" />
                                                    <Field name="refuse" as={SettingsTextInput} label="Refuse" prepend="R" currency="true" />
                                                    <Field name="key_deposit" as={SettingsTextInput} label="Key Deposit" prepend="R" currency="true" />
                                                    <Field name="effluent" as={SettingsTextInput} label="Effluent" prepend="R" currency="true" />
                                                    <Field name="special_deduction" as={SettingsTextInput} label="Deduction" prepend="R" currency="true" />
                                                    <Field name="lease_start" type="text" as={SettingsDatePicker} label="Lease Start Date" />
                                                    <Field name="lease_end" type="text" as={SettingsDatePicker} label="Lease End Date" />
                                                </div>
                                                <p className="font-medium mt-8 text-gray-800">Document Specific</p>
                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <Field name="invoice_monthly_total" as={SettingsSwitch} label="Ignore monthly rental towards total" />
                                                    <Field name="invoice_monthly_hide" as={SettingsSwitch} label="Hide Monthly Rental" />
                                                    <Field name="monthly_rental_wording" as={SettingsTextInput} label="Monthly Rental Wording" />
                                                    <Field name="special_wording" as={SettingsTextInput} label="Special Wording" />
                                                </div>
                                                <div className="mt-8">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={regular("save")}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Tab.Panel>
                            {/* Next of Kin */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Next of Kin Information</h1>
                                </div>
                                <Formik
                                    initialValues={nextOfKinInitialValues}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);

                                        //format cell
                                        values.kin_contact = values.kin_contact && values.kin_contact.replace("+", "");

                                        let submitData = {
                                            user: current_staff,
                                            url: url,
                                            kin_title: values.kin_title,
                                            kin_name: values.kin_name,
                                            kin_surname: values.kin_surname,
                                            kin_relationship: values.kin_relationship,
                                            kin_contact: values.kin_contact,
                                            kin_email: values.kin_email,
                                            kin_address: values.kin_address,
                                        };

                                        await axios({
                                            method: "put",
                                            url: `${process.env.REACT_APP_API_URL}user`,
                                            data: submitData,
                                        })
                                            .then((response) => {
                                                console.log(response);
                                                toast(
                                                    <NotificationToast title={current_booking.user.name + " " + current_booking.user.surname + " updated"} message={"Your changes have been saved."} />
                                                );
                                                getUser();

                                                //Add the + back for form prettiness
                                                values.kin_contact = "+" + values.kin_contact;
                                            })
                                            .catch((error) => {
                                                console.log(error.response);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                                resetForm();
                                            });
                                    }}
                                >
                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">
                                                    This information is related to the Next of Kin for {current_booking.user.name} {current_booking.user.surname} (Applicant One)
                                                </p>

                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <SettingsSelect
                                                        label="Title"
                                                        name="kin_title"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.kin_title}
                                                        touched={touched.kin_title}
                                                        error={errors.kin_title}
                                                        options="titles"
                                                    />
                                                    <Field name="kin_name" type="text" as={SettingsTextInput} label="Name" />
                                                    <Field name="kin_surname" type="text" as={SettingsTextInput} label="Surname" />
                                                    <Field name="kin_relationship" type="text" as={SettingsTextInput} label="Relationship" />
                                                    {/* <Field name="kin_contact" type="text" as={SettingsTextInput} label="Contact" prepend="+" /> */}
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="kin_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Cell Number</span>
                                                            {errors.kin_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("kin_contact", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="kin_contact"
                                                                value={values.kin_contact}
                                                            />
                                                            <p className="text-red-400 text-sm font-light">{errors.kin_contact}</p>
                                                        </div>
                                                    </div>
                                                    <Field name="kin_email" type="email" as={SettingsTextInput} label="Email" />
                                                    <Field name="kin_address" type="text" as={SettingsTextInput} label="Address" />
                                                </div>

                                                <div className="mt-8">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={regular("save")}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Tab.Panel>
                            {/* Affordability Calculator */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Affordability Calculator</h1>
                                </div>
                                <Formik
                                    initialValues={affordabilityCalculatorInitialValues}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);
                                        let submitData = {
                                            user: current_staff,
                                            url: url,
                                            gross_salary: formatToFloat(values.gross_salary),
                                            other_income: formatToFloat(values.other_income),
                                            net_income: formatToFloat(values.net_income),
                                            rent: formatToFloat(values.rent),
                                            car_repayments: formatToFloat(values.car_repayments),
                                            loan_repayments: formatToFloat(values.loan_repayments),
                                            card_repayments: formatToFloat(values.card_repayments),
                                            groceries_costs: formatToFloat(values.groceries_costs),
                                            fuel_costs: formatToFloat(values.fuel_costs),
                                            school_fees: formatToFloat(values.school_fees),
                                            medical_aid: formatToFloat(values.medical_aid),
                                            total_monthly: formatToFloat(values.total_monthly),
                                            available_income: availableIncome(values.net_income, values.total_monthly),
                                            other: values.other,
                                        };

                                        await axios({
                                            method: "put",
                                            url: `${process.env.REACT_APP_API_URL}user`,
                                            data: submitData,
                                        })
                                            .then((response) => {
                                                toast(
                                                    <NotificationToast title={current_booking.user.name + " " + current_booking.user.surname + " updated"} message={"Your changes have been saved."} />
                                                );
                                                getUser();
                                            })
                                            .catch((error) => {
                                                console.log(error.response);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                                resetForm();
                                            });
                                    }}
                                >
                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">
                                                    This information is related to the affordability for {current_booking.user.name} {current_booking.user.surname} (Applicant One)
                                                </p>

                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <Field name="gross_salary" as={SettingsTextInput} label="Gross Salary" prepend="R" currency="true" />
                                                    <Field name="other_income" as={SettingsTextInput} label="Other Income" prepend="R" currency="true" />
                                                    <Field name="net_income" as={SettingsTextInput} label="Net Income (A)" prepend="R" currency="true" />
                                                    <Field name="rent" as={SettingsTextInput} label="Rent" prepend="R" currency="true" />
                                                    <Field name="car_repayments" as={SettingsTextInput} label="Car Repayments" prepend="R" currency="true" />
                                                    <Field name="loan_repayments" as={SettingsTextInput} label="Loan Repayments" prepend="R" currency="true" />
                                                    <Field name="card_repayments" as={SettingsTextInput} label="Card Repayments" prepend="R" currency="true" />
                                                    <Field name="groceries_costs" as={SettingsTextInput} label="Groceries Costs" prepend="R" currency="true" />
                                                    <Field name="fuel_costs" as={SettingsTextInput} label="Fuel Costs" prepend="R" currency="true" />
                                                    <Field name="school_fees" as={SettingsTextInput} label="School Fees" prepend="R" currency="true" />
                                                    <Field name="medical_aid" as={SettingsTextInput} label="Medical Aid" prepend="R" currency="true" />
                                                    <Field name="total_monthly" as={SettingsTextInput} label="Total Monthly Expenses (B)" prepend="R" currency="true" />
                                                    <Field
                                                        name="available_income"
                                                        as={SettingsTextInput}
                                                        label="Available Income (A-B)"
                                                        disabled
                                                        value={availableIncome(values.net_income, values.total_monthly)}
                                                        prepend="R"
                                                        currency="true"
                                                    />
                                                    <Field name="other" field_type="textarea" as={SettingsTextInput} label="Other" />
                                                </div>

                                                <div className="mt-8">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={regular("save")}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Tab.Panel>
                            {/* Audit Log */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Audit Log</h1>
                                    <FontAwesomeIcon
                                        icon={regular("sync")}
                                        className={classNames(auditloading ? "animate-spin" : "", "ml-6 leading-6 text-white cursor-pointer")}
                                        onClick={() => fetchAudit()}
                                    />
                                </div>
                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <ul role="list" className="space-y-6">
                                        {log.length &&
                                            log.map((l, index) => {
                                                return (
                                                    <li key={l.id}>
                                                        <div className="relative pb-8">
                                                            {index !== log.length - 1 ? <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" /> : null}
                                                            <div className="relative flex space-x-3">
                                                                <FontAwesomeIcon icon={formatIcon(l.event)} className={`${formatIconClass(l.event)} + h-8 w-8`} />
                                                                <div className="flex-1 space-y-1">
                                                                    <div className="flex items-center justify-between border-b-2 border-gray-200">
                                                                        <p className="text-md text-gray-900 font-medium">
                                                                            <span className="">
                                                                                {l.user ? `${l.user.name} ${l.user.surname}` : `${current_booking.user.name} ${current_booking.user.surname}`}
                                                                                {" " + l.event + " " + formatLogModel(l.auditable_type) + " "}-
                                                                            </span>
                                                                            <span className="text-md text-gray-600">
                                                                                {" "}
                                                                                {l.auditable_id} {formatLogTitle(l)}
                                                                            </span>
                                                                        </p>
                                                                        <p>{moment(l.created_at).format("DD/MM/YYYY H:m:ss")}</p>
                                                                    </div>
                                                                    <div className="flex ">
                                                                        <div className="w-1/2">
                                                                            {l.old_values && l.event === "updated" && (
                                                                                <>
                                                                                    <p className="font-medium">From</p>
                                                                                    <div className="flex">
                                                                                        <ul>
                                                                                            {Object.keys(l.old_values).map((key) => (
                                                                                                <li key={key} className="font-light capitalize">
                                                                                                    {key} -
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                        <ul>
                                                                                            {Object.values(l.old_values).map((value) => (
                                                                                                <li key={value} className="font-light ml-2 capitalize">
                                                                                                    {" "}
                                                                                                    {formatLogBooleans(value)}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className="w-1/2">
                                                                            {l.new_values && l.event === "updated" && (
                                                                                <>
                                                                                    <p className="font-medium">To</p>
                                                                                    <div className="flex">
                                                                                        <ul>
                                                                                            {Object.keys(l.new_values).map((key) => (
                                                                                                <li key={key} className="font-light capitalize">
                                                                                                    {key} -
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                        <ul>
                                                                                            {Object.values(l.new_values).map((value) => (
                                                                                                <li key={value} className="font-light ml-2 capitalize">
                                                                                                    {" "}
                                                                                                    {formatLogBooleans(value)}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            </Tab.Panel>
                            {/* Notes */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Notes</h1>
                                    <FontAwesomeIcon
                                        icon={regular("sync")}
                                        className={classNames(notesLoading ? "animate-spin" : "", "ml-6 leading-6 text-white cursor-pointer")}
                                        onClick={() => fetchNotes()}
                                    />
                                </div>
                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <ul role="list" className="space-y-6">
                                        {notes.length &&
                                            notes.map((n, index) => {
                                                return (
                                                    <li key={n.id}>
                                                        <div className="relative pb-8">
                                                            <div className="relative flex space-x-3">
                                                                <div className="flex-1 space-y-1">
                                                                    <div className="flex items-center justify-between border-b-2 border-gray-200">
                                                                        <div className="text-md text-gray-900 font-medium">
                                                                            {n.user[0]?.name != undefined ? n.user[0]?.name : "" + " " + n.user[0]?.surname != undefined ? n.user[0]?.surname : ""}
                                                                        </div>
                                                                        <p>{moment(n.created_at).format("DD/MM/YYYY H:m:ss")}</p>
                                                                    </div>
                                                                    <div className="flex ">{n.message}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </>
    );
}
