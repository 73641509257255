import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import * as Yup from "yup";
import { Tab } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsDatePicker from "../Forms/SettingsDatePicker";
import SettingsSwitch from "../Forms/SettingsSwitch";
import SubmitButton from "../Forms/SubmitButton";
import SettingsCounter from "../Forms/SettingsCounter";
import AppLoader from "../Helpers/AppLoader";
import PhoneInput from "react-phone-number-input";

import { faBuildingCircleCheck, faUser, faBriefcase, faCreditCard, faHouse, faPeople, faBank, faUserGroup } from "@fortawesome/pro-regular-svg-icons";
import CustomButton from "../Helpers/CustomButton";
import QLDocumentUploadComp from "./QLDocumentUploadComp";

const subNavigation = [
    { name: "Your Booking", icon: faBuildingCircleCheck },
    { name: "Applicant One", icon: faUser },
    { name: " - Employment Information", icon: faBriefcase },
    { name: " - Banking Details", icon: faBank },
    { name: " - Next of Kin", icon: faPeople },
    { name: " - Affordability", icon: faCreditCard },
];
const jointSubNavigation = [
    { name: "Applicant Two", icon: faUserGroup },
    { name: " - Banking Details", icon: faBank },
    { name: " - Current Premises", icon: faHouse },
    { name: " - Affordability", icon: faCreditCard },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function ApplicationForm() {
    const dispatch = useDispatch();
    const { url } = useParams();
    const [loading, setLoading] = useState(false);
    const [fileUploadInformation, setFileUploadInformation] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    // const [initialValues, setInitialValues] = useState({lease_surance: 1});
    const [properties, setProperties] = useState([]);
    // const { current_booking } = useSelector((state) => state.booking);
    const [current_booking, setCurrentBooking] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [bankList, setBankList] = useState([]);

    const myRefAp1 = useRef(null);
    const myRefAp1Emp = useRef(null);
    const myRefAp1Aff = useRef(null);
    const myRefAp1Com = useRef(null);
    const myRefAp2 = useRef(null);
    const myRefAp1Bnk = useRef(null);
    const myRefAp1NOK = useRef(null);
    const myRefAp2Aff = useRef(null);
    const myRefAp2Bnk = useRef(null);
    const myRefTerms = useRef(null);
    const myRefAp2Prem = useRef(null);
    const myRefDocs = useRef(null);

    useEffect(async () => {
        setLoading(true);
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                setProperties(response.data);
            })
            .catch((error) => {
                alert("properties failed");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}paystack_banks`,
        })
            .then((response) => {
                let banks = response.data.data;
                let bankList = [];

                Object.keys(banks).forEach((key) => {
                    bankList.push({ key: banks[key].name, value: banks[key].name, label: banks[key].name, code: banks[key].code });
                });

                setBankList(bankList);
            })
            .catch((error) => {
                alert("Bank failed");
            });

        if (url) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
            })
                .then((response) => {
                    setCurrentBooking(response.data);

                    getUnitTypes(response.data.property_id);

                    setInitialValues({
                        name: response.data.user.name,
                        surname: response.data.user.surname !== null ? response.data.user.surname : "",
                        email: response.data.user.email,
                        cell: response.data.user.cell !== "" ? "+" + response.data.user.cell : "",
                        title: response.data.user.profile && response.data.user.profile.title !== null ? response.data.user.profile.title : "",
                        nationality: response.data.user.profile && response.data.user.profile.nationality !== null ? response.data.user.profile.nationality : "",
                        id_number: response.data.user.profile && response.data.user.profile.id_number !== null ? response.data.user.profile.id_number : "",
                        date_of_birth: response.data.user.profile && response.data.user.profile.date_of_birth !== null ? response.data.user.profile.date_of_birth : "",

                        car: response.data.user.profile ? response.data.user.profile.car : "",
                        occupation: response.data.user.profile ? response.data.user.profile.occupation : "",
                        home_number: response.data.user.profile && response.data.user.profile.home_number ? "+" + response.data.user.profile.home_number : "",
                        employer: response.data.user.profile ? response.data.user.profile.employer : "",
                        employer_address: response.data.user.profile ? response.data.user.profile.employer_address : "",
                        employer_suburb: response.data.user.profile ? response.data.user.profile.employer_suburb : "",
                        employer_city: response.data.user.profile ? response.data.user.profile.employer_city : "",
                        employer_province: response.data.user.profile ? response.data.user.profile.employer_province : "",
                        employer_postal: response.data.user.profile ? response.data.user.profile.employer_postal : "",
                        employer_registration: response.data.user.profile ? response.data.user.profile.employer_registration : "",
                        employer_it: response.data.user.profile ? response.data.user.profile.employer_it : "",
                        employer_contact: response.data.user.profile && response.data.user.profile.employer_contact ? "+" + response.data.user.profile.employer_contact : "",
                        self_employed: response.data.user.profile ? response.data.user.profile.self_employed : 0,

                        banking_bank: response.data.user.profile ? response.data.user.profile.banking_bank : "",
                        banking_account_type: response.data.user.profile ? response.data.user.profile.banking_account_type : "",
                        banking_branch: response.data.user.profile ? response.data.user.profile.banking_branch : "",
                        banking_branch_code: response.data.user.profile ? response.data.user.profile.banking_branch_code : "",
                        banking_account_number: response.data.user.profile ? response.data.user.profile.banking_account_number : "",
                        banking_account_holder: response.data.user.profile ? response.data.user.profile.banking_account_holder : "",
                        criminal_record: response.data.user.profile ? response.data.user.profile.criminal_record : "",
                        other: response.data.user.profile ? response.data.user.profile.other : "",
                        current_address: response.data.user.premises ? response.data.user.premises.current_address : "",
                        current_suburb: response.data.user.premises ? response.data.user.premises.current_suburb : "",
                        current_city: response.data.user.premises ? response.data.user.premises.current_city : "",
                        current_postal: response.data.user.premises ? response.data.user.premises.current_postal : "",
                        current_province: response.data.user.premises ? response.data.user.premises.current_province : "",
                        landlord_name: response.data.user.premises ? response.data.user.premises.landlord_name : "",
                        landlord_contact: response.data.user.premises ? response.data.user.premises.landlord_contact : "",

                        company_application: response.data.company_application ? response.data.company_application : 0,
                        joint_application: response.data.joint_application,
                        joint_application_title: response.data.user.joint_application ? response.data.user.joint_application.title : "",
                        joint_application_name: response.data.user.joint_application ? response.data.user.joint_application.name : "",
                        joint_application_surname: response.data.user.joint_application ? response.data.user.joint_application.surname : "",
                        joint_application_email: response.data.user.joint_application ? response.data.user.joint_application.email : "",
                        joint_application_id_number: response.data.user.joint_application ? response.data.user.joint_application.id_number : "",
                        joint_application_nationality: response.data.user.joint_application ? response.data.user.joint_application.nationality : "",
                        joint_application_cell: response.data.user.joint_application && response.data.user.joint_application.cell ? "+" + response.data.user.joint_application.cell : "",
                        joint_application_date_of_birth: response.data.user.joint_application ? response.data.user.joint_application.date_of_birth : "",
                        joint_application_occupation: response.data.user.joint_application ? response.data.user.joint_application.occupation : "",
                        joint_application_employer: response.data.user.joint_application ? response.data.user.joint_application.employer : "",

                        joint_application_gross_salary: response.data.user.joint_application ? response.data.user.joint_application.gross_salary : "",
                        joint_application_other_income: response.data.user.joint_application ? response.data.user.joint_application.other_income : "",
                        joint_application_net_income: response.data.user.joint_application ? response.data.user.joint_application.net_income : "",
                        joint_application_rent: response.data.user.joint_application ? response.data.user.joint_application.rent : "",
                        joint_application_car_repayments: response.data.user.joint_application ? response.data.user.joint_application.car_repayments : "",
                        joint_application_loan_repayments: response.data.user.joint_application ? response.data.user.joint_application.loan_repayments : "",
                        joint_application_card_repayments: response.data.user.joint_application ? response.data.user.joint_application.card_repayments : "",
                        joint_application_groceries_costs: response.data.user.joint_application ? response.data.user.joint_application.groceries_costs : "",
                        joint_application_fuel_costs: response.data.user.joint_application ? response.data.user.joint_application.fuel_costs : "",
                        joint_application_school_fees: response.data.user.joint_application ? response.data.user.joint_application.school_fees : "",
                        joint_application_medical_aid: response.data.user.joint_application ? response.data.user.joint_application.medical_aid : "",
                        joint_application_total_monthly: response.data.user.joint_application ? response.data.user.joint_application.total_monthly : "",
                        joint_application_available_income: response.data.user.joint_application ? response.data.user.joint_application.available_income : "",
                        joint_application_other: response.data.user.joint_application ? response.data.user.joint_application.other : "",

                        joint_application_banking_bank: response.data.user.joint_application ? response.data.user.joint_application.banking_bank : "",
                        joint_application_banking_account_type: response.data.user.joint_application ? response.data.user.joint_application.banking_account_type : "",
                        joint_application_banking_branch: response.data.user.joint_application ? response.data.user.joint_application.banking_branch : "",
                        joint_application_banking_branch_code: response.data.user.joint_application ? response.data.user.joint_application.banking_branch_code : "",
                        joint_application_banking_account_number: response.data.user.joint_application ? response.data.user.joint_application.banking_account_number : "",
                        joint_application_banking_account_holder: response.data.user.joint_application ? response.data.user.joint_application.banking_account_holder : "",

                        joint_application_current_address: response.data.user.joint_application ? response.data.user.joint_application.current_address : "",
                        joint_application_current_suburb: response.data.user.joint_application ? response.data.user.joint_application.current_suburb : "",
                        joint_application_current_city: response.data.user.joint_application ? response.data.user.joint_application.current_city : "",
                        joint_application_current_postal: response.data.user.joint_application ? response.data.user.joint_application.current_postal : "",
                        joint_application_current_province: response.data.user.joint_application ? response.data.user.joint_application.current_province : "",

                        property_id: response.data.property_id,
                        // unit_type: response.data.unit ? response.data.unit.id : "",
                        unit_type: response.data.unit_type ? response.data.unit_type.id : "",

                        occupy_unit: response.data.user.premises ? response.data.user.premises.occupy_unit : "",
                        parking: response.data.user.premises ? parseInt(response.data.user.premises.parking) : "",
                        // viewed_unit: response.data.premises ? response.data.premises.viewed_unit : "",
                        occupants_over_10: response.data.user.premises ? response.data.user.premises.occupants_over_10 : "",
                        occupants: response.data.user.premises ? response.data.user.premises.occupants : 0,
                        referral: response.data.user.premises ? response.data.user.premises.referral : "",
                        // assisted_by: response.data.premises ? response.data.premises.assisted_by : "",

                        invoice_monthly_total: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].invoice_monthly_total : "",
                        invoice_monthly_hide: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].invoice_monthly_hide : "",
                        parking_fee: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].parking_fee : "",
                        monthly_rental: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].monthly_rental : "",
                        monthly_rental_wording: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].monthly_rental_wording : "",
                        admin_fee: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].admin_fee : "",
                        deposit_price: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].deposit_price : "",
                        lease_surance: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].lease_surance : "",
                        booking_fee_price: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].booking_fee_price : "",
                        key_deposit: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].key_deposit : "",
                        carport: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].carport : "",
                        special_wording: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].special_wording : "",
                        effluent: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].effluent : "",
                        special_deduction: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].special_deduction : "",
                        refuse: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].refuse : "",
                        lease_start: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].lease_start : "",
                        lease_end: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].lease_end : "",

                        kin_title: response.data.user.next_of_kin && response.data.user.next_of_kin.title !== null ? response.data.user.next_of_kin.title : "",
                        kin_name: response.data.user.next_of_kin && response.data.user.next_of_kin.name !== null ? response.data.user.next_of_kin.name : "",
                        kin_surname: response.data.user.next_of_kin && response.data.user.next_of_kin.surname !== null ? response.data.user.next_of_kin.surname : "",
                        kin_relationship: response.data.user.next_of_kin && response.data.user.next_of_kin.relationship !== null ? response.data.user.next_of_kin.relationship : "",
                        kin_contact: response.data.user.next_of_kin && response.data.user.next_of_kin.contact !== null ? "+" + response.data.user.next_of_kin.contact : "",
                        kin_email: response.data.user.next_of_kin && response.data.user.next_of_kin.email !== null ? response.data.user.next_of_kin.email : "",
                        kin_address: response.data.user.next_of_kin && response.data.user.next_of_kin.address !== null ? response.data.user.next_of_kin.address : "",
                        kin_id_number: response.data.user.next_of_kin && response.data.user.next_of_kin.id_number !== null ? response.data.user.next_of_kin.id_number : "",
                        kin_suburb: response.data.user.next_of_kin && response.data.user.next_of_kin.suburb !== null ? response.data.user.next_of_kin.suburb : "",
                        kin_postal: response.data.user.next_of_kin && response.data.user.next_of_kin.postal !== null ? response.data.user.next_of_kin.postal : "",
                        kin_city: response.data.user.next_of_kin && response.data.user.next_of_kin.city !== null ? response.data.user.next_of_kin.city : "",
                        kin_tel_contact: response.data.user.next_of_kin && response.data.user.next_of_kin.tel_contact !== null ? "+" + response.data.user.next_of_kin.tel_contact : "",
                        kin_work_contact: response.data.user.next_of_kin && response.data.user.next_of_kin.work_contact !== null ? "+" + response.data.user.next_of_kin.work_contact : "",

                        gross_salary: response.data.user.affordability ? response.data.user.affordability.gross_salary : "",
                        other_income: response.data.user.affordability ? response.data.user.affordability.other_income : "",
                        net_income: response.data.user.affordability ? response.data.user.affordability.net_income : "",
                        rent: response.data.user.affordability ? response.data.user.affordability.rent : "",
                        car_repayments: response.data.user.affordability ? response.data.user.affordability.car_repayments : "",
                        loan_repayments: response.data.user.affordability ? response.data.user.affordability.loan_repayments : "",
                        card_repayments: response.data.user.affordability ? response.data.user.affordability.card_repayments : "",
                        groceries_costs: response.data.user.affordability ? response.data.user.affordability.groceries_costs : "",
                        fuel_costs: response.data.user.affordability ? response.data.user.affordability.fuel_costs : "",
                        school_fees: response.data.user.affordability ? response.data.user.affordability.school_fees : "",
                        medical_aid: response.data.user.affordability ? response.data.user.affordability.medical_aid : "",
                        total_monthly: response.data.user.affordability ? response.data.user.affordability.total_monthly : "",
                        available_income: response.data.user.affordability ? response.data.user.affordability.available_income : "",
                        other: response.data.user.affordability ? response.data.user.affordability.other : "",
                    });

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    // window.location.href = "/404";
                });

            // axios({
            //     method: "get",
            //     url: `${process.env.REACT_APP_API_URL}edit_user/${url}`,
            // })
            //     .then((response) => {
            //         const data = response.data.user;
            //         if (data.bookings[0].property_id) getUnitTypes(data.bookings[0].property_id);
            //         setFileUploadInformation({
            //             booking_id: data.active_booking,
            //             user_id: data.id,
            //             added_by: data.id,
            //         });

            //         dispatch(getBooking(data.active_booking));

            //         setInitialValues({
            //             name: data.name,
            //             surname: data.surname,
            //             email: data.email,
            //             cell: "+" + data.cell,
            //             title: data.profile ? data.profile.title : "",
            //             nationality: data.profile ? data.profile.nationality : "",
            //             id_number: data.profile ? data.profile.id_number : "",
            //             car: data.profile ? data.profile.car : "",
            //             occupation: data.profile ? data.profile.occupation : "",
            //             home_number: data.profile && data.profile.home_number ? "+" + data.profile.home_number : "",
            //             employer: data.profile ? data.profile.employer : "",
            //             employer_address: data.profile ? data.profile.employer_address : "",
            //             employer_suburb: data.profile ? data.profile.employer_suburb : "",
            //             employer_city: data.profile ? data.profile.employer_city : "",
            //             employer_province: data.profile ? data.profile.employer_province : "",
            //             employer_postal: data.profile ? data.profile.employer_postal : "",
            //             employer_registration: data.profile ? data.profile.employer_registration : "",
            //             employer_it: data.profile ? data.profile.employer_it : "",
            //             employer_contact: data.profile && data.profile.employer_contact ? "+" + data.profile.employer_contact : "",
            //             date_of_birth: data.profile ? data.profile.date_of_birth : "",
            //             banking_bank: data.profile ? data.profile.banking_bank : "",
            //             banking_account_type: data.profile ? data.profile.banking_account_type : "",
            //             banking_branch: data.profile ? data.profile.banking_branch : "",
            //             banking_branch_code: data.profile ? data.profile.banking_branch_code : "",
            //             banking_account_number: data.profile ? data.profile.banking_account_number : "",
            //             banking_account_holder: data.profile ? data.profile.banking_account_holder : "",
            //             criminal_record: data.profile ? data.profile.criminal_record : "",
            //             other: data.profile ? data.profile.other : "",
            //             current_address: data.premises ? data.premises.current_address : "",
            //             current_suburb: data.premises ? data.premises.current_suburb : "",
            //             current_city: data.premises ? data.premises.current_city : "",
            //             current_postal: data.premises ? data.premises.current_postal : "",
            //             current_province: data.premises ? data.premises.current_province : "",
            //             landlord_name: data.premises ? data.premises.landlord_name : "",
            //             landlord_contact: data.premises ? data.premises.landlord_contact : "",

            //             company_application: data.bookings[0].company_application ? data.bookings[0].company_application : 0,
            //             joint_application: data.bookings[0].joint_application,
            //             joint_application_title: data.joint_application ? data.joint_application.title : "",
            //             joint_application_name: data.joint_application ? data.joint_application.name : "",
            //             joint_application_surname: data.joint_application ? data.joint_application.surname : "",
            //             joint_application_email: data.joint_application ? data.joint_application.email : "",
            //             joint_application_id_number: data.joint_application ? data.joint_application.id_number : "",
            //             joint_application_nationality: data.joint_application ? data.joint_application.nationality : "",
            //             joint_application_cell: data.joint_application && data.joint_application.cell ? "+" + data.joint_application.cell : "",
            //             joint_application_date_of_birth: data.joint_application ? data.joint_application.date_of_birth : "",
            //             joint_application_employer: data.joint_application ? data.joint_application.employer : "",

            //             joint_application_gross_salary: data.joint_application ? data.joint_application.gross_salary : "",
            //             joint_application_other_income: data.joint_application ? data.joint_application.other_income : "",
            //             joint_application_net_income: data.joint_application ? data.joint_application.net_income : "",
            //             joint_application_rent: data.joint_application ? data.joint_application.rent : "",
            //             joint_application_car_repayments: data.joint_application ? data.joint_application.car_repayments : "",
            //             joint_application_loan_repayments: data.joint_application ? data.joint_application.loan_repayments : "",
            //             joint_application_card_repayments: data.joint_application ? data.joint_application.card_repayments : "",
            //             joint_application_groceries_costs: data.joint_application ? data.joint_application.groceries_costs : "",
            //             joint_application_fuel_costs: data.joint_application ? data.joint_application.fuel_costs : "",
            //             joint_application_school_fees: data.joint_application ? data.joint_application.school_fees : "",
            //             joint_application_medical_aid: data.joint_application ? data.joint_application.medical_aid : "",
            //             joint_application_total_monthly: data.joint_application ? data.joint_application.total_monthly : "",
            //             joint_application_available_income: data.joint_application ? data.joint_application.available_income : "",
            //             joint_application_other: data.joint_application ? data.joint_application.other : "",

            //             joint_application_banking_bank: data.joint_application ? data.joint_application.banking_bank : "",
            //             joint_application_banking_account_type: data.joint_application ? data.joint_application.banking_account_type : "",
            //             joint_application_banking_branch: data.joint_application ? data.joint_application.banking_branch : "",
            //             joint_application_banking_branch_code: data.joint_application ? data.joint_application.banking_branch_code : "",
            //             joint_application_banking_account_number: data.joint_application ? data.joint_application.banking_account_number : "",
            //             joint_application_banking_account_holder: data.joint_application ? data.joint_application.banking_account_holder : "",

            //             joint_application_current_address: data.joint_application ? data.joint_application.current_address : "",
            //             joint_application_current_suburb: data.joint_application ? data.joint_application.current_suburb : "",
            //             joint_application_current_city: data.joint_application ? data.joint_application.current_city : "",
            //             joint_application_current_postal: data.joint_application ? data.joint_application.current_postal : "",
            //             joint_application_current_province: data.joint_application ? data.joint_application.current_province : "",

            //             property_id: data.bookings[0].property_id,
            //             unit_type: data.bookings[0].unit_type,

            //             occupy_unit: data.premises ? data.premises.occupy_unit : "",
            //             parking: data.premises ? data.premises.parking : "",
            //             // viewed_unit: data.premises ? data.premises.viewed_unit : "",
            //             occupants_over_10: data.premises ? data.premises.occupants_over_10 : "",
            //             occupants: data.premises ? data.premises.occupants : 0,
            //             referral: data.premises ? data.premises.referral : "",
            //             // assisted_by: data.premises ? data.premises.assisted_by : "",

            //             invoice_monthly_total: data.bookings[0].rates[0] ? data.bookings[0].rates[0].invoice_monthly_total : "",
            //             invoice_monthly_hide: data.bookings[0].rates[0] ? data.bookings[0].rates[0].invoice_monthly_hide : "",
            //             parking_fee: data.bookings[0].rates[0] ? data.bookings[0].rates[0].parking_fee : "",
            //             monthly_rental: data.bookings[0].rates[0] ? data.bookings[0].rates[0].monthly_rental : "",
            //             monthly_rental_wording: data.bookings[0].rates[0] ? data.bookings[0].rates[0].monthly_rental_wording : "",
            //             admin_fee: data.bookings[0].rates[0] ? data.bookings[0].rates[0].admin_fee : "",
            //             deposit_price: data.bookings[0].rates[0] ? data.bookings[0].rates[0].deposit_price : "",
            //             booking_fee_price: data.bookings[0].rates[0] ? data.bookings[0].rates[0].booking_fee_price : "",
            //             key_deposit: data.bookings[0].rates[0] ? data.bookings[0].rates[0].key_deposit : "",
            //             carport: data.bookings[0].rates[0] ? data.bookings[0].rates[0].carport : "",
            //             special_wording: data.bookings[0].rates[0] ? data.bookings[0].rates[0].special_wording : "",
            //             effluent: data.bookings[0].rates[0] ? data.bookings[0].rates[0].effluent : "",
            //             special_deduction: data.bookings[0].rates[0] ? data.bookings[0].rates[0].special_deduction : "",
            //             refuse: data.bookings[0].rates[0] ? data.bookings[0].rates[0].refuse : "",
            //             lease_start: data.bookings[0].rates[0] ? data.bookings[0].rates[0].lease_start : "",
            //             lease_end: data.bookings[0].rates[0] ? data.bookings[0].rates[0].lease_end : "",

            //             kin_title: data.next_of_kin ? data.next_of_kin.title : "",
            //             kin_name: data.next_of_kin ? data.next_of_kin.name : "",
            //             kin_surname: data.next_of_kin ? data.next_of_kin.surname : "",
            //             kin_relationship: data.next_of_kin ? data.next_of_kin.relationship : "",
            //             kin_contact: data.next_of_kin && data.next_of_kin.contact ? "+" + data.next_of_kin.contact : "",
            //             kin_email: data.next_of_kin ? data.next_of_kin.email : "",
            //             kin_address: data.next_of_kin ? data.next_of_kin.address : "",

            //             gross_salary: data.affordability ? data.affordability.gross_salary : "",
            //             other_income: data.affordability ? data.affordability.other_income : "",
            //             net_income: data.affordability ? data.affordability.net_income : "",
            //             rent: data.affordability ? data.affordability.rent : "",
            //             car_repayments: data.affordability ? data.affordability.car_repayments : "",
            //             loan_repayments: data.affordability ? data.affordability.loan_repayments : "",
            //             card_repayments: data.affordability ? data.affordability.card_repayments : "",
            //             groceries_costs: data.affordability ? data.affordability.groceries_costs : "",
            //             fuel_costs: data.affordability ? data.affordability.fuel_costs : "",
            //             school_fees: data.affordability ? data.affordability.school_fees : "",
            //             medical_aid: data.affordability ? data.affordability.medical_aid : "",
            //             total_monthly: data.affordability ? data.affordability.total_monthly : "",
            //             available_income: data.affordability ? data.affordability.available_income : "",
            //             other: data.affordability ? data.affordability.other : "",
            //         });
            //     })
            //     .catch((error) => {
            //         window.location.href = "/404";
            //     })
            //     .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [setProperties, setInitialValues]);

    if (loading === true) return <AppLoader />;

    async function getUnitTypes(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
        }).then((response) => {
            setUnitTypes(response.data);
        });
    }

    function formatToFloat(value) {
        if (value) {
            return parseFloat(value.replace(/,/g, ""));
        } else {
            return value;
        }
    }

    function availableIncome(income, outcome) {
        var money_in = formatToFloat(income);
        var money_out = formatToFloat(outcome);

        return money_in - money_out;
    }

    if (
        current_booking &&
        current_booking.rates &&
        current_booking.rates.length > 0 &&
        current_booking.rates[current_booking.rates.length - 1].signed_lease_at !== "" &&
        current_booking.rates[current_booking.rates.length - 1].signed_lease_at !== null
    ) {
        return (
            <main className="mt-14">
                <div className="max-w-7xl mx-auto pb-2 px-4">
                    <h2>Your lease has been signed, no futher updates to your information are allowed.</h2>
                </div>
            </main>
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize="true"
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={Yup.object().shape({
                property_id: Yup.number().required("desired building"),
                unit_type: Yup.number().required("type of unit"),
                title: Yup.number().required("title for Applicant One"),
                name: Yup.string().required("name for Applicant One"),
                surname: Yup.string().required("surname for Applicant One"),
                email: Yup.string().required("email for Applicant One"),
                cell: Yup.number().required("phone number for Applicant One"),
                nationality: Yup.string().required("nationality for Applicant One"),
                date_of_birth: Yup.string().required("date of birth for Applicant One"),
                id_number: Yup.string().required("ID or passport number for Applicant One"),
                // criminal_record: Yup.string().required( "criminal record for Applicant One" ),
                occupation: Yup.string().required("occupation of Applicant One"),
                employer: Yup.string().required("employer name for Applicant one"),
                banking_bank: Yup.string().nullable().required("name of the bank for Applicant One"),
                banking_account_type: Yup.string().nullable().required("type of account for Applicant One"),
                banking_branch: Yup.string().nullable().required("bank branch for Applicant One"),
                banking_branch_code: Yup.string().nullable().required("bank branch code for Applicant One"),
                banking_account_number: Yup.string().nullable().required("bank acount number for Applicant One"),
                banking_account_holder: Yup.string().nullable().required("banking account holder for Applicant One"),
                gross_salary: Yup.string().required("gross salary of Applicant One"),
                net_income: Yup.string().required("net income of Applicant One"),
                // groceries_costs: Yup.string().required("costs of groceries of Applicant One"),
                total_monthly: Yup.string().nullable().required("total monthly expenses of Applicant One"),

                employer_contact: Yup.string().when("self_employed", {
                    is: (value) => value && value == 1,
                    then: Yup.string().nullable(),
                    otherwise: Yup.string().required("employer contact for Applicant One"),
                }),

                car: Yup.string().when("parking", {
                    is: (value) => value && value == "1",
                    then: Yup.string().nullable().required("Car registration Number"),
                    otherwise: Yup.string().nullable(),
                }),

                joint_application_title: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("title for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_name: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("name for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_surname: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("surname for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_email: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("email for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_cell: Yup.number().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.number().required("cell number for Applicant Two"),
                    otherwise: Yup.number().nullable(),
                }),
                joint_application_id_number: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("id or passport number for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_nationality: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("nationality of Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_date_of_birth: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("date of birth of Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_occupation: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("occupation of Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_employer: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("employer for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_banking_bank: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().nullable().required("name of the bank for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_banking_account_type: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().nullable().required("type of account for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_banking_branch_code: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().nullable().required("bank branch code for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_banking_account_number: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().nullable().required("bank account number for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_banking_account_holder: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().nullable().required("banking account holder for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_current_address: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("current address for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                // joint_application_current_suburb: Yup.string().when("joint_application", {
                //     is: (value) => value && value == 1,
                //     then: Yup.string().required("current suburb for Applicant Two"),
                //     otherwise: Yup.string().nullable(),
                // }),
                // joint_application_current_city: Yup.string().when("joint_application", {
                //     is: (value) => value && value == 1,
                //     then: Yup.string().required("current city for Applicant Two"),
                //     otherwise: Yup.string().nullable(),
                // }),
                // joint_application_current_postal: Yup.string().when("joint_application", {
                //     is: (value) => value && value == 1,
                //     then: Yup.string().required("current postal code for Applicant Two"),
                //     otherwise: Yup.string().nullable(),
                // }),
                joint_application_current_province: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("current province for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_gross_salary: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("gross salary of Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_net_income: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("net income of Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_total_monthly: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("total monthly expenses of Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                popi: Yup.number().positive("confirmation to the POPI Act").min(1, "confirmation to the POPI Act"),
                legal: Yup.number().positive("confirmation that your information is legally correct").min(1, "confirmation that your information is legally correct"),
                tpn: Yup.number().positive("confirmation that a Credit Check will be run").min(1, "confirmation that a Credit Check will be run"),
                admin: Yup.number().positive("confirmation that you will pay the non-refundable admin fee").min(1, "confirmation that you will pay the non-refundable admin fee"),
                // company application

                landlord_name: Yup.string().when("company_application", {
                    is: (value) => value == 0 || value == undefined,
                    then: Yup.string().required("landlord name of Applicant One"),
                    otherwise: Yup.string().nullable(),
                }),
                landlord_contact: Yup.string().when("company_application", {
                    is: (value) => value == 0 || value == undefined,
                    then: Yup.string().required("landlord contact of Applicant One"),
                    otherwise: Yup.string().nullable(),
                }),
                current_address: Yup.string().when("company_application", {
                    is: (value) => value == 0 || value == undefined,
                    then: Yup.string().required("current address of Applicant One"),
                    otherwise: Yup.string().nullable(),
                }),
                // current_suburb: Yup.string().when("company_application", {
                //     is: (value) => value == 0 || value == undefined,
                //     then: Yup.string().required("current suburb of Applicant One"),
                //     otherwise: Yup.string().nullable(),
                // }),
                // current_city: Yup.string().when("company_application", {
                //     is: (value) => value == 0 || value == undefined,
                //     then: Yup.string().required("current city of Applicant One"),
                //     otherwise: Yup.string().nullable(),
                // }),
                // current_postal: Yup.string().when("company_application", {
                //     is: (value) => value == 0 || value == undefined,
                //     then: Yup.string().required("current postal code of Applicant One"),
                //     otherwise: Yup.string().nullable(),
                // }),
                current_province: Yup.string().when("company_application", {
                    is: (value) => value == 0 || value == undefined,
                    then: Yup.string().required("current province of Applicant One"),
                    otherwise: Yup.string().nullable(),
                }),
                employer_address: Yup.string().when("company_application", {
                    is: (value) => value && value === 1,
                    then: Yup.string().nullable().required("employer address for Applicant One"),
                    otherwise: Yup.string().nullable(),
                }),
                employer_suburb: Yup.string().when("company_application", {
                    is: (value) => value && value === 1,
                    then: Yup.string().nullable().required("employer suburb for Applicant One"),
                    otherwise: Yup.string().nullable(),
                }),
                employer_city: Yup.string().when("company_application", {
                    is: (value) => value && value === 1,
                    then: Yup.string().nullable().required("employer city for Applicant One"),
                    otherwise: Yup.string().nullable(),
                }),
                employer_postal: Yup.string().when("company_application", {
                    is: (value) => value && value === 1,
                    then: Yup.string().nullable().required("employer postal code for Applicant One"),
                    otherwise: Yup.string().nullable(),
                }),
                employer_province: Yup.string().when("company_application", {
                    is: (value) => value && value === 1,
                    then: Yup.string().nullable().required("employer province for Applicant One"),
                    otherwise: Yup.string().nullable(),
                }),
                employer_registration: Yup.string().when("company_application", {
                    is: (value) => value && value === 1,
                    then: Yup.string().nullable().required("employer registration for Applicant One"),
                    otherwise: Yup.string().nullable(),
                }),
                employer_it: Yup.string().when("company_application", {
                    is: (value) => value && value === 1,
                    then: Yup.string().nullable().required("employer it number for Applicant One"),
                    otherwise: Yup.string().nullable(),
                }),
            })}
            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                setSubmitting(true);

                //format cell
                values.cell = values.cell && values.cell.replace("+", "");
                values.home_number = values.home_number && values.home_number.replace("+", "");
                values.employer_contact = values.employer_contact && values.employer_contact.replace("+", "");
                values.joint_application_cell = values.joint_application_cell && values.joint_application_cell.replace("+", "");
                values.kin_contact = values.kin_contact && values.kin_contact.replace("+", "");

                // adjust the financial information to floats
                // deep clone object to not adjust original values
                let data = JSON.parse(JSON.stringify(values));
                data.available_income = availableIncome(data.net_income, data.total_monthly);

                data.gross_salary = data.gross_salary ? formatToFloat(data.gross_salary) : null;

                data.other_income = data.other_income ? formatToFloat(data.other_income) : null;
                data.net_income = data.net_income ? formatToFloat(data.net_income) : null;
                data.rent = data.rent ? formatToFloat(data.rent) : null;
                data.car_repayments = data.car_repayments ? formatToFloat(data.car_repayments) : null;
                data.loan_repayments = data.loan_repayments ? formatToFloat(data.loan_repayments) : null;
                data.card_repayments = data.card_repayments ? formatToFloat(data.card_repayments) : null;
                data.groceries_costs = data.groceries_costs ? formatToFloat(data.groceries_costs) : null;
                data.fuel_costs = data.fuel_costs ? formatToFloat(data.fuel_costs) : null;
                data.school_fees = data.school_fees ? formatToFloat(data.school_fees) : null;
                data.medical_aid = data.medical_aid ? formatToFloat(data.medical_aid) : null;
                data.total_monthly = data.total_monthly ? formatToFloat(data.total_monthly) : null;

                await Object.keys(bankList).forEach((key) => {
                    if (data.banking_bank == bankList[key].label) {
                        data.banking_bank_code = bankList[key].code;
                    }
                });

                if (data.joint_application == 1) {
                    data.joint_application_available_income = availableIncome(data.joint_application_net_income, data.joint_application_total_monthly);

                    data.joint_application_gross_salary = data.joint_application_gross_salary ? formatToFloat(data.joint_application_gross_salary) : null;
                    data.joint_application_other_income = data.joint_application_other_income ? formatToFloat(data.joint_application_other_income) : null;
                    data.joint_application_net_income = data.joint_application_net_income ? formatToFloat(data.joint_application_net_income) : null;
                    data.joint_application_rent = data.joint_application_rent ? formatToFloat(data.joint_application_rent) : null;
                    data.joint_application_car_repayments = data.joint_application_car_repayments ? formatToFloat(data.joint_application_car_repayments) : null;
                    data.joint_application_loan_repayments = data.joint_application_loan_repayments ? formatToFloat(data.joint_application_loan_repayments) : null;
                    data.joint_application_card_repayments = data.joint_application_card_repayments ? formatToFloat(data.joint_application_card_repayments) : null;
                    data.joint_application_groceries_costs = data.joint_application_groceries_costs ? formatToFloat(data.joint_application_groceries_costs) : null;
                    data.joint_application_fuel_costs = data.joint_application_fuel_costs ? formatToFloat(data.joint_application_fuel_costs) : null;
                    data.joint_application_school_fees = data.joint_application_school_fees ? formatToFloat(data.joint_application_school_fees) : null;
                    data.joint_application_medical_aid = data.joint_application_medical_aid ? formatToFloat(data.joint_application_medical_aid) : null;
                    data.joint_application_total_monthly = data.joint_application_total_monthly ? formatToFloat(data.joint_application_total_monthly) : null;

                    await Object.keys(bankList).forEach((key) => {
                        if (data.joint_application_banking_bank == bankList[key].label) {
                            data.banking_bank_code_joint = bankList[key].code;
                        }
                    });
                }

                let api_method = "post";
                if (url) {
                    api_method = "put";
                    data.url = url;

                    data.lease_surance = current_booking.rates[current_booking.rates.length - 1].lease_surance;
                }

                await axios({
                    method: api_method,
                    url: `${process.env.REACT_APP_API_URL}user`,
                    data: data,
                })
                    .then((response) => {
                        if (url) {
                            toast(
                                <NotificationToast
                                    title={"Application Updated"}
                                    message="Your application has succesfully been updated. Keep an eye out on your email and cellphone for future communication"
                                />
                            );
                        } else {
                            toast(
                                <NotificationToast
                                    title={"Application Successful. Redirecting to Edit form"}
                                    message="Your application has succesfully been created. Keep an eye out on your email and cellphone for future communication"
                                />
                            );
                            setTimeout(() => {
                                window.location = window.location.origin + "/edit_application/" + response.data;
                            }, 3000);
                        }

                        // Add the + back for form prettiness
                        values.cell = "+" + values.cell;
                        values.home_number = "+" + values.home_number;
                        values.employer_contact = "+" + values.employer_contact;
                        values.joint_application_cell = "+" + values.joint_application_cell;
                        values.kin_contact = "+" + values.kin_contact;
                    })
                    .catch((response) => {
                        toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                    });
            }}
        >
            {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="pb-32 elative bg-fixed bg-bottom bg-cover bg-no-repeat application_form">
                        <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                            <div className="lg:flex">
                                <img className="h-24" src="/images/logo.svg" alt="Quorum Living" />
                                <div className="py-6 lg:py-0 mx-auto md:w-96 text-center">
                                    <h1 className="text-3xl text-white">
                                        Welcome {url && "back"} to QLiving {url && current_booking && current_booking.user && current_booking.user.name + " " + current_booking.user.surname}
                                    </h1>
                                    {url ? (
                                        <p className="text-gray-200 mt-4">
                                            Update your information at each <span className="italic font-bold">Section</span> and update your application by clicking{" "}
                                            <span className="italic font-bold">Submit Application</span>.
                                        </p>
                                    ) : (
                                        <p className="text-gray-200 mt-4">
                                            Complete the form below by filling in all the required information at each <span className="italic font-bold">Section</span> and start your application by
                                            clicking <span className="italic font-bold">Submit Application</span>.
                                        </p>
                                    )}
                                    {Object.keys(errors).length > 0 && (
                                        <>
                                            <p className="mt-6 text-red-500 text-lg font-bold">Cannot apply!</p>{" "}
                                            <p className="my-2 text-red-500 text-sm">Please make sure you fill in all sections of the form below:</p>
                                        </>
                                    )}
                                    {Object.keys(errors).length < 1 && (
                                        <div className="px-8">
                                            <SubmitButton
                                                name="Save"
                                                disabled={!isValid || isSubmitting || !dirty}
                                                isSubmitting={isSubmitting}
                                                text={url ? "Update Application" : "Submit Application"}
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </header>
                    </div>
                    <main className="-mt-32">
                        <div className="max-w-7xl mx-auto pb-2 px-4">
                            <div className="space-y-8 divide-y divide-gray-200">
                                <div className="w-full">
                                    <div className="xl:grid xl:grid-cols-12">
                                        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                            {/* <Tab.List className="pb-6 lg:pb-24 bg-light_background xl:col-span-2 rounded-3xl lg:rounded-l-3xl w-full xl:w-72 border-0">
                                                <div className="h-14 flex items-center rounded-t-3xl xl:rounded-tl-3xl xl:rounded-tr-none justify-center bg-dark_background">
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Sections</h1>
                                                </div>
                                                {subNavigation.map((item) => (
                                                    <Tab
                                                        key={item.name}
                                                        className={({ selected }) =>
                                                            classNames(
                                                                "group w-full text-sm flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none ",
                                                                selected ? "border-l-primary bg-gray-100 text-primary" : "bg-light_background border-l-light_background"
                                                            )
                                                        }
                                                    >
                                                        <span className="truncate">
                                                            <FontAwesomeIcon icon={item.icon} aria-hidden="true" className="mr-4" />
                                                            {item.name}
                                                        </span>
                                                    </Tab>
                                                ))}
                                                {!values.company_application && (
                                                    <Tab
                                                        className={({ selected }) =>
                                                            classNames(
                                                                "group w-full text-sm flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none ",
                                                                selected ? "border-l-primary bg-gray-100 text-primary" : "bg-light_background border-l-light_background"
                                                            )
                                                        }
                                                    >
                                                        <span className="truncate">
                                                            <FontAwesomeIcon icon={faHouse} aria-hidden="true" className="mr-4" />- Current Premises
                                                        </span>
                                                    </Tab>
                                                )}
                                                {values.joint_application == 1 &&
                                                    jointSubNavigation.map((item) => (
                                                        <Tab
                                                            key={item.name}
                                                            className={({ selected }) =>
                                                                classNames(
                                                                    "group w-full text-sm flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none ",
                                                                    selected ? "border-l-primary bg-gray-100 text-primary" : "bg-light_background border-l-light_background"
                                                                )
                                                            }
                                                        >
                                                            <span className="truncate">
                                                                <FontAwesomeIcon icon={item.icon} aria-hidden="true" className="mr-4" />
                                                                {item.name}
                                                            </span>
                                                        </Tab>
                                                    ))}
                                            </Tab.List> */}
                                            <Tab.Panels className="xl:ml-20 my-6 xl:my-0 rounded-3xl xl:rounded-3xl bg-gray-100 xl:col-span-11 w-full">
                                                {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                <div className="h-14 flex items-center rounded-t-3xl justify-center bg-dark_background">
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Your Booking</h1>
                                                </div>

                                                {/* {url && <QLDocumentUploadComp url={url} />} */}

                                                <div className="py-8 px-4 sm:px-12 space-y-6  xl:px-6">
                                                    <Field name="joint_application" as={SettingsSwitch} label="Is this a Joint Application" />
                                                    <Field name="company_application" as={SettingsSwitch} label="Is this a Company Application" />
                                                </div>
                                                <div className="px-4 sm:px-12 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="What is your desired Building?"
                                                            name="property_id"
                                                            required
                                                            getUnitTypes={getUnitTypes}
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.property_id}
                                                            touched={touched.property_id}
                                                            error={errors.property_id}
                                                            options={properties}
                                                        />
                                                        {errors.property_id && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.property_id}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="What type of unit would you like?"
                                                            required
                                                            name="unit_type"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.unit_type}
                                                            touched={touched.unit_type}
                                                            error={errors.unit_type}
                                                            options={unitTypes.length > 0 ? unitTypes : "none"}
                                                        />
                                                        {errors.unit_type && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.unit_type}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <Field name="viewed_unit" as={ SettingsSwitch } label="Have you viewed the Unit?"
                                                            setFieldValue={ setFieldValue } /> */}
                                                    <Field name="parking" as={SettingsSwitch} label="Do you require parking?" />

                                                    <Field
                                                        name="occupants"
                                                        as={SettingsCounter}
                                                        value={values.occupants}
                                                        label="What is the total number of occupants?"
                                                        setFieldValue={setFieldValue}
                                                    />
                                                    {values.occupants > 0 && <Field name="occupants_over_10" as={SettingsSwitch} label="Are any of the other occupants under the age of 10?" />}
                                                    {/* <SettingsSelect
                                                            label="Assisted By"
                                                            name="assisted_by"
                                                            onChange={ setFieldValue }
                                                            onBlur={ setFieldTouched }
                                                            value={ values.assisted_by }
                                                            touched={ touched.assisted_by }
                                                            error={ errors.assisted_by }
                                                            options="assisted_by"
                                                        /> */}
                                                    <SettingsSelect
                                                        label="Where did you hear about us?"
                                                        name="referral"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.referral}
                                                        touched={touched.referral}
                                                        error={errors.referral}
                                                        options="referral"
                                                    />
                                                </div>
                                                <div className="py-8 px-4 sm:px-12 space-y-6  xl:px-6">
                                                    {/* {url ? "" :
                                                            <>
                                                                <Field
                                                                    name="lease_surance"
                                                                    as={SettingsSwitch}
                                                                    label="Standard Cash Deposit OR Deposit Waiver Fee"
                                                                    description="Q Living now offers a Deposit Waiver fee. Instead of paying an upfront deposit, you can choose to rather pay a monthly deposit waiver fee equal to 5% of your monthly rental. This frees up some extra cash for you when moving in!  At the end of your lease term, and should you not wish to renew, you may be able to simply move out. All that is required of you is to comply with the terms of your lease agreement throughout your stay. No hidden charges. No catch. Cha-Ching 💸.

                                                                    Toggle the option to yes (✓) to opt for the deposit waiver fee"
                                                                />
                                                            </>
                                                        } */}
                                                </div>
                                                {!(errors.property_id || errors.unit_type) ? (
                                                    <div className="flex justify-around">
                                                        <CustomButton text={"Continue"} onClick={() => myRefAp1.current.scrollIntoView()} />
                                                    </div>
                                                ) : (
                                                    // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                    //     <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                    //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                    // </div>
                                                    ""
                                                )}
                                                {/* </Tab.Panel> */}
                                                {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                <div className="h-14 flex items-center justify-center bg-dark_background mt-5" ref={myRefAp1}>
                                                    <a href={"#"}></a>
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant One</h1>
                                                </div>
                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                    <SettingsSelect
                                                        label="Will this Applicant occupy the premises?                                                        "
                                                        name="occupy_unit"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.occupy_unit}
                                                        touched={touched.occupy_unit}
                                                        error={errors.occupy_unit}
                                                        options="yesNo"
                                                    />
                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="Title"
                                                            name="title"
                                                            required
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.title}
                                                            touched={touched.title}
                                                            error={errors.title}
                                                            options="titles"
                                                        />
                                                        {errors.title && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.title}</span>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-span-3">
                                                        <Field name="name" type="text" as={SettingsTextInput} label="Name" required />
                                                        {errors.name && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.name}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="surname" type="text" as={SettingsTextInput} label="Surname" required />
                                                        {errors.surname && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.surname}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="email" type="email" as={SettingsTextInput} label="Email" required />
                                                        {errors.email && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.email}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <Field name="cell" type="text" as={SettingsTextInput} prepend="+" label="Cell" required /> */}
                                                    <div className="col-span-3">
                                                        <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                            <label htmlFor="cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                <span>Cell</span>
                                                                {errors.cell && <p className="text-xs font-light text-red-400">required</p>}
                                                            </label>
                                                            <div className="mt-1">
                                                                <PhoneInput
                                                                    defaultCountry="ZA"
                                                                    international
                                                                    countryCallingCodeEditable={false}
                                                                    onChange={(val) => {
                                                                        setFieldValue("cell", val);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    name="cell"
                                                                    value={values.cell}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        {errors.cell && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.cell}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="home_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Home Number</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("home_number", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="home_number"
                                                                value={values.home_number}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <Field name="home_number" type="text" as={SettingsTextInput} label="Home Number" prepend="+" /> */}
                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="Nationality"
                                                            name="nationality"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.nationality}
                                                            touched={touched.nationality}
                                                            error={errors.nationality}
                                                            options="nationality"
                                                            required
                                                        />
                                                        {errors.nationality && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.nationality}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" required nationality={values.nationality} />
                                                        {errors.id_number && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.id_number}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field
                                                            name="date_of_birth"
                                                            type="text"
                                                            as={SettingsDatePicker}
                                                            label="Date of Birth"
                                                            id_number={values.id_number}
                                                            nationality={values.nationality}
                                                            required
                                                        />
                                                        {errors.date_of_birth && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.date_of_birth}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="car" type="text" as={SettingsTextInput} label="Car Model and Registration" />
                                                        {errors.car && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.car}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <SettingsSelect
                                                        label="Criminal Record"
                                                        name="criminal_record"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.criminal_record}
                                                        touched={touched.criminal_record}
                                                        error={errors.criminal_record}
                                                        options="yesNo"
                                                    />
                                                    <Field name="other" field_type="textarea" as={SettingsTextInput} label="Other" />
                                                </div>
                                                <div className="flex justify-around">
                                                    {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                    {!(
                                                        errors.title ||
                                                        errors.name ||
                                                        errors.surname ||
                                                        errors.email ||
                                                        errors.cell ||
                                                        errors.nationality ||
                                                        errors.id_number ||
                                                        errors.date_of_birth
                                                    ) ? (
                                                        <div className="flex justify-around">
                                                            <CustomButton text={"Continue"} onClick={() => myRefAp1Emp.current.scrollIntoView()} />
                                                        </div>
                                                    ) : (
                                                        // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        //     <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                        // </div>
                                                        ""
                                                    )}
                                                </div>
                                                {/* </Tab.Panel>

                                                <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp1Emp}>
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant One - Employment Information</h1>
                                                </div>

                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                    {" "}
                                                    <div className="col-span-3">
                                                        <Field name="occupation" type="text" as={SettingsTextInput} label="Occupation" required />
                                                        {errors.occupation && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.occupation}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* self_employed */}
                                                    <Field name="self_employed" as={SettingsSwitch} label="Are you self-employed?" />
                                                    <div className="col-span-3">
                                                        <Field name="employer" type="text" as={SettingsTextInput} label="Employer Name" required />
                                                        {errors.employer && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.employer}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <Field name="employer_address" type="text" as={SettingsTextInput} label="Employer Address" />
                                                    {values.company_application && (
                                                        <>
                                                            <div className="col-span-3">
                                                                <Field name="employer_suburb" type="text" as={SettingsTextInput} label="Employer Suburb" required />
                                                                {errors.employer_suburb && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.employer_suburb}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="employer_city" type="text" as={SettingsTextInput} label="Employer City" required />
                                                                {errors.employer_city && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.employer_city}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="employer_postal" type="text" as={SettingsTextInput} label="Employer Postal Code" required />
                                                                {errors.employer_postal && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.employer_postal}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <SettingsSelect
                                                                    label="Employer Province"
                                                                    name="employer_province"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.employer_province}
                                                                    touched={touched.employer_province}
                                                                    error={errors.employer_province}
                                                                    options="provinces"
                                                                    required
                                                                />
                                                                {errors.employer_province && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.employer_province}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field
                                                                    name="employer_registration"
                                                                    as={SettingsTextInput}
                                                                    label="Employer Registration Number"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    business_registration={true}
                                                                    required
                                                                />
                                                                {errors.employer_registration && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.employer_registration}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="employer_it" type="text" as={SettingsTextInput} label="Employer IT Number" required />
                                                                {errors.employer_it && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.employer_it}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                    {/* <Field name="employer_contact" type="text" as={SettingsTextInput} label="Employer Contact Number" prepend="+" /> */}
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="employer_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Employer Contact Number</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("employer_contact", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="employer_contact"
                                                                value={values.employer_contact}
                                                            />
                                                        </div>

                                                        {errors.employer_contact && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.employer_contact}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex justify-around">
                                                    {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                    {!(
                                                        errors.employer ||
                                                        errors.employer_suburb ||
                                                        errors.employer_city ||
                                                        errors.employer_postal ||
                                                        errors.employer_province ||
                                                        errors.employer_registration ||
                                                        errors.employer_it ||
                                                        errors.occupation ||
                                                        errors.employer_contact
                                                    ) ? (
                                                        <div className="flex justify-around">
                                                            <CustomButton text={"Continue"} onClick={() => myRefAp1Aff.current.scrollIntoView()} />
                                                        </div>
                                                    ) : (
                                                      ''
                                                    )}
                                                </div>
                                                {/* </Tab.Panel> */}
                                                {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp1Aff}>
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant One - Affordability</h1>
                                                </div>

                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                    <div className="col-span-3">
                                                        <Field name="gross_salary" as={SettingsTextInput} label="Gross Salary" prepend="R" currency="true" required small="(Before Deductions & Tax)" />
                                                        {errors.gross_salary && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.gross_salary}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <Field name="other_income" as={SettingsTextInput} label="Other Income" prepend="R" currency="true" /> */}
                                                    <div className="col-span-3">
                                                        <Field
                                                            name="net_income"
                                                            as={SettingsTextInput}
                                                            label="Net Income (A)"
                                                            prepend="R"
                                                            currency="true"
                                                            required
                                                            small="(Gross + Other = Net Income)"
                                                        />
                                                        {errors.net_income && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.net_income}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <Field name="rent" as={SettingsTextInput} label="Rent" prepend="R" currency="true" /> */}
                                                    {/* <Field name="car_repayments" as={SettingsTextInput} label="Car Repayments" prepend="R" currency="true" /> */}
                                                    {/* <Field name="loan_repayments" as={SettingsTextInput} label="Loan Repayments" prepend="R" currency="true" /> */}
                                                    {/* <Field name="card_repayments" as={SettingsTextInput} label="Card Repayments" prepend="R" currency="true" /> */}
                                                    {/* <div className="col-span-3">
                                                        <Field name="groceries_costs" as={SettingsTextInput} label="Groceries Costs" prepend="R" currency="true" required />
                                                        {errors.groceries_costs && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.groceries_costs}</span>
                                                            </div>
                                                        )}
                                                    </div> */}
                                                    {/* <Field name="fuel_costs" as={SettingsTextInput} label="Fuel Costs" prepend="R" currency="true" /> */}
                                                    {/* <Field name="school_fees" as={SettingsTextInput} label="School Fees" prepend="R" currency="true" /> */}
                                                    {/* <Field name="medical_aid" as={SettingsTextInput} label="Medical Aid" prepend="R" currency="true" /> */}
                                                    <div className="col-span-3">
                                                        <Field name="total_monthly" as={SettingsTextInput} label="Total Monthly Expenses (B)" prepend="R" currency="true" required />
                                                        {errors.total_monthly && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.total_monthly}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <Field
                                                        name="available_income"
                                                        as={SettingsTextInput}
                                                        label="Available Income (A-B)"
                                                        disabled
                                                        value={availableIncome(values.net_income, values.total_monthly)}
                                                        prepend="R"
                                                        currency="true"
                                                    />
                                                    <Field name="other" field_type="textarea" as={SettingsTextInput} label="Other" />
                                                </div>
                                                <div className="flex justify-around">
                                                    {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                    {errors.gross_salary || errors.net_income || errors.total_monthly ? (
                                                        // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        //     <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                        // </div>
                                                        ""
                                                    ) : !values.company_application ? (
                                                        <CustomButton text={"Continue"} onClick={() => myRefAp1Com.current.scrollIntoView()} />
                                                    ) : values.joint_application ? (
                                                        <CustomButton text={"Continue"} onClick={() => myRefAp2.current.scrollIntoView()} />
                                                    ) : (
                                                        // Object.keys(errors).length < 1 && (
                                                        // <SubmitButton
                                                        //     name="Save"
                                                        //     disabled={!isValid || isSubmitting || !dirty}
                                                        //     isSubmitting={isSubmitting}
                                                        //     text={url ? "Update Application" : "Submit Application"}
                                                        // />
                                                        // )
                                                        <CustomButton text={"Continue"} onClick={() => myRefAp1Bnk.current.scrollIntoView()} />
                                                    )}
                                                </div>
                                                {/* </Tab.Panel> */}
                                                {!values.company_application && (
                                                    <>
                                                        <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp1Com}>
                                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant One - Current Premises</h1>
                                                        </div>

                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                            {" "}
                                                            <div className="col-span-3">
                                                                <Field name="current_address" type="text" as={SettingsTextInput} label="Current Address" required />
                                                                {errors.current_address && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.current_address}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="current_suburb" type="text" as={SettingsTextInput} label="Current Suburb" />
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="current_city" type="text" as={SettingsTextInput} label="Current City" />
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="current_postal" type="text" as={SettingsTextInput} label="Current Postal Code" />
                                                            </div>
                                                            <div className="col-span-3">
                                                                <SettingsSelect
                                                                    label="Current Province"
                                                                    name="current_province"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.current_province}
                                                                    touched={touched.current_province}
                                                                    error={errors.current_province}
                                                                    options="provinces"
                                                                    required
                                                                />
                                                                {errors.current_province && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.current_province}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="landlord_name" type="text" as={SettingsTextInput} label="Landlord Name" required />
                                                                {errors.landlord_name && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.landlord_name}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                    <label htmlFor="joint_application_cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                        <span>Landlord Contact</span>
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <PhoneInput
                                                                            defaultCountry="ZA"
                                                                            international
                                                                            countryCallingCodeEditable={false}
                                                                            onChange={(val) => {
                                                                                setFieldValue("landlord_contact", val);
                                                                            }}
                                                                            onBlur={setFieldTouched}
                                                                            name="landlord_contact"
                                                                            value={values.landlord_contact}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {errors.landlord_contact && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.landlord_contact}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-around">
                                                            {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                            {errors.current_address || errors.current_province || errors.landlord_name || errors.landlord_contact ? (
                                                                // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                //     <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                                // </div>
                                                                ""
                                                            ) : values.joint_application ? (
                                                                <CustomButton text={"Continue"} onClick={() => myRefAp1Bnk.current.scrollIntoView()} />
                                                            ) : (
                                                                // Object.keys(errors).length < 1 && (
                                                                //     <SubmitButton
                                                                //         name="Save"
                                                                //         disabled={!isValid || isSubmitting || !dirty}
                                                                //         isSubmitting={isSubmitting}
                                                                //         text={url ? "Update Application" : "Submit Application"}
                                                                //     />
                                                                // )
                                                                <CustomButton text={"Continue"} onClick={() => myRefTerms.current.scrollIntoView()} />
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                                {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp1Bnk}>
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant One - Banking Details</h1>
                                                </div>

                                                {values.company_application && (
                                                    <h2 className="pt-6 px-4 text-center leading-6 font-light text-dark_gray capitalize">Please enter the banking details of your company</h2>
                                                )}
                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                    {" "}
                                                    <div className="col-span-3">
                                                        {/* <Field name="banking_bank" type="text" as={SettingsTextInput} label="Bank" required /> */}
                                                        <SettingsSelect
                                                            label="Bank"
                                                            name="banking_bank"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.banking_bank}
                                                            touched={touched.banking_bank}
                                                            error={errors.banking_bank}
                                                            options={bankList}
                                                        />
                                                        {errors.banking_bank && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.banking_bank}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="banking_account_type" type="text" as={SettingsTextInput} label="Account Type" required />
                                                        {errors.banking_account_type && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.banking_account_type}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="banking_branch" type="text" as={SettingsTextInput} label="Branch" required />
                                                        {errors.banking_branch && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.banking_branch}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="banking_branch_code" type="text" as={SettingsTextInput} label="Branch Code" required />
                                                        {errors.banking_branch_code && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.banking_branch_code}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="banking_account_number" type="text" as={SettingsTextInput} label="Account Number" required />
                                                        {errors.banking_account_number && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.banking_account_number}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="banking_account_holder" type="text" as={SettingsTextInput} label="Account Holder" required />
                                                        {errors.banking_account_holder && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.banking_account_holder}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex justify-around">
                                                    {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                    {!(
                                                        errors.banking_bank ||
                                                        errors.banking_account_type ||
                                                        errors.banking_branch ||
                                                        errors.banking_branch_code ||
                                                        errors.banking_account_number ||
                                                        errors.banking_account_holder
                                                    ) ? (
                                                        <div className="flex justify-around">
                                                            <CustomButton text={"Continue"} onClick={() => myRefAp1NOK.current.scrollIntoView()} />
                                                        </div>
                                                    ) : (
                                                        // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        //     <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                        // </div>
                                                        ""
                                                    )}
                                                </div>
                                                {/* </Tab.Panel> */}

                                                {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp1NOK}>
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant One - Next of Kin</h1>
                                                </div>

                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                    <SettingsSelect
                                                        label="Title"
                                                        name="kin_title"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.kin_title}
                                                        touched={touched.kin_title}
                                                        error={errors.kin_title}
                                                        options="titles"
                                                    />
                                                    <Field name="kin_name" type="text" as={SettingsTextInput} label="Name" />
                                                    <Field name="kin_surname" type="text" as={SettingsTextInput} label="Surname" />
                                                    <Field name="kin_relationship" type="text" as={SettingsTextInput} label="Relationship" />
                                                    {/* <Field name="kin_contact" type="text" as={SettingsTextInput} label="Contact" prepend="+" /> */}
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="kin_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Contact</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("kin_contact", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="kin_contact"
                                                                value={values.kin_contact}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Field name="kin_email" type="email" as={SettingsTextInput} label="Email" />
                                                    <Field name="kin_address" type="text" as={SettingsTextInput} label="Address" />
                                                </div>
                                                <div className="flex justify-around">
                                                    {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                    <CustomButton text={"Continue"} onClick={() => myRefAp2.current.scrollIntoView()} />
                                                </div>
                                                {/* </Tab.Panel> */}

                                                {values.joint_application == 1 && (
                                                    <>
                                                        {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                        <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp2}>
                                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant Two</h1>
                                                        </div>

                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                            {" "}
                                                            <div className="col-span-3">
                                                                <SettingsSelect
                                                                    label="Title"
                                                                    name="joint_application_title"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.joint_application_title}
                                                                    touched={touched.joint_application_title}
                                                                    error={errors.joint_application_title}
                                                                    options="titles"
                                                                />
                                                                {errors.joint_application_title && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_title}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_name" type="text" as={SettingsTextInput} label="Name" />
                                                                {errors.joint_application_name && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_name}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_surname" type="text" as={SettingsTextInput} label="Surname" />
                                                                {errors.joint_application_surname && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_surname}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_email" type="email" as={SettingsTextInput} label="Email" />
                                                                {errors.joint_application_email && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_email}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* <Field name="joint_application_cell" type="text" as={SettingsTextInput} label="Cell Number" prepend="+" /> */}
                                                            <div className="col-span-3">
                                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                    <label htmlFor="joint_application_cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                        <span>Cell Number</span>
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <PhoneInput
                                                                            defaultCountry="ZA"
                                                                            international
                                                                            countryCallingCodeEditable={false}
                                                                            onChange={(val) => {
                                                                                setFieldValue("joint_application_cell", val);
                                                                            }}
                                                                            onBlur={setFieldTouched}
                                                                            name="joint_application_cell"
                                                                            value={values.joint_application_cell}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {errors.joint_application_cell && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_cell}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <SettingsSelect
                                                                    label="Nationality"
                                                                    name="joint_application_nationality"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.joint_application_nationality}
                                                                    touched={touched.joint_application_nationality}
                                                                    error={errors.joint_application_nationality}
                                                                    options="nationality"
                                                                />

                                                                {errors.joint_application_nationality && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_nationality}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field
                                                                    name="joint_application_id_number"
                                                                    type="text"
                                                                    as={SettingsTextInput}
                                                                    label="ID or Passport Number"
                                                                    nationality={values.joint_application_nationality}
                                                                />
                                                                {errors.joint_application_id_number && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_id_number}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field
                                                                    name="joint_application_date_of_birth"
                                                                    type="text"
                                                                    as={SettingsDatePicker}
                                                                    label="Date of Birth"
                                                                    id_number={values.joint_application_id_number}
                                                                    nationality={values.joint_application_nationality}
                                                                    required
                                                                />
                                                                {errors.joint_application_date_of_birth && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_date_of_birth}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_occupation" type="text" as={SettingsTextInput} label="Occupation" required />
                                                                {errors.joint_application_occupation && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_occupation}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_employer" type="text" as={SettingsTextInput} label="Employer" required />
                                                                {errors.joint_application_employer && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_employer}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-around">
                                                            {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                            {!(
                                                                errors.joint_application_title ||
                                                                errors.joint_application_name ||
                                                                errors.joint_application_surname ||
                                                                errors.joint_application_email ||
                                                                errors.joint_application_id_number ||
                                                                errors.joint_application_occupation ||
                                                                errors.joint_application_employer ||
                                                                errors.joint_application_nationality
                                                            ) ? (
                                                                <div className="flex justify-around">
                                                                    <CustomButton text={"Continue"} onClick={() => myRefAp2Aff.current.scrollIntoView()} />
                                                                </div>
                                                            ) : (
                                                                // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                //     <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                                // </div>
                                                                ""
                                                            )}
                                                        </div>
                                                        {/* </Tab.Panel> */}

                                                        {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                        <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp2Aff}>
                                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant Two - Affordability</h1>
                                                        </div>

                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                            <div className="col-span-3">
                                                                <Field
                                                                    name="joint_application_gross_salary"
                                                                    as={SettingsTextInput}
                                                                    label="Gross Salary"
                                                                    prepend="R"
                                                                    currency="true"
                                                                    small="(Before Deductions & Tax)"
                                                                />
                                                                {errors.joint_application_gross_salary && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_gross_salary}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* <Field name="joint_application_other_income" as={SettingsTextInput} label="Other Income" prepend="R" currency="true" /> */}
                                                            <div className="col-span-3">
                                                                <Field
                                                                    name="joint_application_net_income"
                                                                    as={SettingsTextInput}
                                                                    label="Net Income (A)"
                                                                    prepend="R"
                                                                    currency="true"
                                                                    small="(Gross + Other = Net Income)"
                                                                />
                                                                {errors.joint_application_net_income && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_net_income}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* <Field name="joint_application_rent" as={SettingsTextInput} label="Rent" prepend="R" currency="true" /> */}
                                                            {/* <Field name="joint_application_car_repayments" as={SettingsTextInput} label="Car Repayments" prepend="R" currency="true" /> */}
                                                            {/* <Field name="joint_application_loan_repayments" as={SettingsTextInput} label="Loan Repayments" prepend="R" currency="true" /> */}
                                                            {/* <Field name="joint_application_card_repayments" as={SettingsTextInput} label="Card Repayments" prepend="R" currency="true" /> */}
                                                            {/* <Field name="joint_application_groceries_costs" as={SettingsTextInput} label="Groceries Costs" prepend="R" currency="true" /> */}
                                                            {/* <Field name="joint_application_fuel_costs" as={SettingsTextInput} label="Fuel Costs" prepend="R" currency="true" /> */}
                                                            {/* <Field name="joint_application_school_fees" as={SettingsTextInput} label="School Fees" prepend="R" currency="true" /> */}
                                                            {/* <Field name="joint_application_medical_aid" as={SettingsTextInput} label="Medical Aid" prepend="R" currency="true" /> */}
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_total_monthly" as={SettingsTextInput} label="Total Monthly Expenses (B)" prepend="R" currency="true" />
                                                                {errors.joint_application_total_monthly && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_total_monthly}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <Field
                                                                name="joint_application_available_income"
                                                                as={SettingsTextInput}
                                                                label="Available Income (A-B)"
                                                                disabled
                                                                value={availableIncome(values.joint_application_net_income, values.joint_application_total_monthly)}
                                                                prepend="R"
                                                                currency="true"
                                                            />
                                                            <Field name="joint_application_other" field_type="textarea" as={SettingsTextInput} label="Other" />
                                                        </div>
                                                        <div className="flex justify-around">
                                                            {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                            {!(errors.joint_application_gross_salary || errors.joint_application_net_income || errors.joint_application_total_monthly) ? (
                                                                //   <div className="flex justify-around">
                                                                //       <SubmitButton
                                                                //           name="Save"
                                                                //           disabled={!isValid || isSubmitting || !dirty || Object.keys(errors).length < 1}
                                                                //           isSubmitting={isSubmitting}
                                                                //           text={url ? "Update Application" : "Submit Application"}
                                                                //       />
                                                                //   </div>
                                                                <div className="flex justify-around">
                                                                    <CustomButton text={"Continue"} onClick={() => myRefAp2Prem.current.scrollIntoView()} />
                                                                </div>
                                                            ) : (
                                                                // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                //     <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                                // </div>
                                                                ""
                                                            )}
                                                        </div>
                                                        {/* </Tab.Panel> */}

                                                        {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                        <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp2Prem}>
                                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant Two - Current Premises</h1>
                                                        </div>

                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_current_address" type="text" as={SettingsTextInput} label="Current Address" required />
                                                                {errors.joint_application_current_address && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_current_address}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_current_suburb" type="text" as={SettingsTextInput} label="Current Suburb" />
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_current_city" type="text" as={SettingsTextInput} label="Current City" />
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_current_postal" type="text" as={SettingsTextInput} label="Current Postal Code" />
                                                            </div>
                                                            <div className="col-span-3">
                                                                <SettingsSelect
                                                                    label="Current Province"
                                                                    name="joint_application_current_province"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.joint_application_current_province}
                                                                    touched={touched.joint_application_current_province}
                                                                    error={errors.joint_application_current_province}
                                                                    options="provinces"
                                                                    required
                                                                />
                                                                {errors.joint_application_current_province && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_current_province}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-around">
                                                            {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                            {!(errors.joint_application_current_address || errors.joint_application_current_province) ? (
                                                                <div className="flex justify-around">
                                                                    <CustomButton text={"Continue"} onClick={() => myRefAp2Bnk.current.scrollIntoView()} />
                                                                </div>
                                                            ) : (
                                                                // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                //     <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                                // </div>
                                                                ""
                                                            )}
                                                        </div>
                                                        {/* </Tab.Panel> */}

                                                        {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                        <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp2Bnk}>
                                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant Two - Banking Details</h1>
                                                        </div>

                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                            {" "}
                                                            <div className="col-span-3">
                                                                <SettingsSelect
                                                                    label="Bank"
                                                                    name="joint_application_banking_bank"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.joint_application_banking_bank}
                                                                    touched={touched.joint_application_banking_bank}
                                                                    error={errors.joint_application_banking_bank}
                                                                    options={bankList}
                                                                />
                                                                {/* <Field name="joint_application_banking_bank" type="text" as={SettingsTextInput} label="Bank" /> */}
                                                                {errors.joint_application_banking_bank && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_banking_bank}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_banking_account_type" type="text" as={SettingsTextInput} label="Account Type" />
                                                                {errors.joint_application_banking_account_type && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_banking_account_type}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_banking_branch" type="text" as={SettingsTextInput} label="Branch" />
                                                                {errors.joint_application_banking_branch && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_banking_branch}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_banking_branch_code" type="text" as={SettingsTextInput} label="Branch Code" />
                                                                {errors.joint_application_banking_branch_code && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_banking_branch_code}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_banking_account_number" type="text" as={SettingsTextInput} label="Account Number" />
                                                                {errors.joint_application_banking_account_number && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_banking_account_number}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_banking_account_holder" type="text" as={SettingsTextInput} label="Account Holder" />
                                                                {errors.joint_application_banking_account_holder && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_banking_account_holder}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-around">
                                                            {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                            {!(
                                                                errors.joint_application_banking_bank ||
                                                                errors.joint_application_banking_account_type ||
                                                                errors.joint_application_banking_branch ||
                                                                errors.joint_application_banking_branch_code ||
                                                                errors.joint_application_banking_account_number ||
                                                                errors.joint_application_banking_account_holder
                                                            ) ? (
                                                                <div className="flex justify-around">
                                                                    <CustomButton text={"Continue"} onClick={() => myRefTerms.current.scrollIntoView()} />
                                                                </div>
                                                            ) : (
                                                                // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                //     <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                                // </div>
                                                                ""
                                                            )}
                                                        </div>
                                                        {/* </Tab.Panel> */}
                                                    </>
                                                )}
                                                <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefDocs}>
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Documents</h1>
                                                </div>

                                                {url && <QLDocumentUploadComp url={url} />}

                                                <div className="flex justify-around">
                                                    <CustomButton text={"Continue"} onClick={() => myRefTerms.current.scrollIntoView()} />
                                                </div>

                                                <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefTerms}>
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Terms</h1>
                                                </div>

                                                <div className="py-8 px-4 sm:px-12 space-y-6  xl:px-6">
                                                    <Field
                                                        name="admin"
                                                        as={SettingsSwitch}
                                                        required
                                                        label={
                                                            // values.property_id === 2
                                                            //     ? "I agree to pay R500 up front in order to review my application, should it be declined, you will be refunded the R 500, should it be successful, it will be added to the admin fee of R700"
                                                            //     :
                                                            "I agree to pay the non-refundable R 1000 Admin Fee"
                                                        }
                                                        description="Upon approval of my application or prior to occupation of the unit"
                                                    />
                                                    {errors.admin && (
                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                            <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                            We require <span className="font-bold italic">{errors.admin}</span>
                                                        </div>
                                                    )}
                                                    <Field
                                                        name="legal"
                                                        as={SettingsSwitch}
                                                        required
                                                        label="I confirm that the information provided is legally correct and binding in a court of law. The Applicant/s hereby warrant/s, represents and undertakes that any information provided to QPG Holdings Proprietary Limited (the 'Company') is true and accurate and it has not knowingly withheld information which, if disclosed, might affect the Company's decision in whether or not to agree to conclude a lease with the Applicant/s."
                                                    />
                                                    {errors.legal && (
                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                            <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                            We require <span className="font-bold italic">{errors.legal}</span>
                                                        </div>
                                                    )}
                                                    <Field
                                                        name="popi"
                                                        as={SettingsSwitch}
                                                        required
                                                        label="I Agree to the POPI ACT"
                                                        description="QPG Holdings Proprietary Limited and its subsidiaries, which includes Urban Circle, is compliant with the Protection of Personal Information Act (POPIA), also known as the POPI Act, which is mandatory for all organisations in South Africa. POPI makes it illegal for us to collect, use or store the personal information of customers and businesses unless it is done in accordance with the laws and regulations prescribed in the POPI Act."
                                                    />
                                                    {errors.popi && (
                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                            <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                            We require <span className="font-bold italic">{errors.popi}</span>
                                                        </div>
                                                    )}
                                                    <Field
                                                        name="tpn"
                                                        as={SettingsSwitch}
                                                        required
                                                        label="I Agree to the Credit Check"
                                                        description="By ticking this box, I hereby agree and acknowledge that QPG Holdings Proprietary Limited may, via any one or more registered credit bureaus, preform any credit search on my credit profile; monitor my payment profile; use any new information or data on any future credit applications; and/or record whether the account is being conducted in a satisfactory manner"
                                                    />
                                                    {errors.tpn && (
                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                            <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                            We require <span className="font-bold italic">{errors.tpn}</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="py-8 px-4 sm:px-12 space-y-6  xl:px-6">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty || Object.keys(errors).length < 1}
                                                        isSubmitting={isSubmitting}
                                                        text={url ? "Update Application" : "Submit Application"}
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            </Tab.Panels>
                                        </Tab.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Form>
            )}
        </Formik>
    );
}
